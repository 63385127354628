import React, { FC } from "react";

const SectionVideo: FC = ({ }) => {
  return (
    <div className="video-main-bx">
      <div className={"video-section-bx"}>
        <iframe src="https://www.youtube.com/embed/hZdGD7qCxGI" title="Yoni Edit Sabbia | Performance | 16:9 | 20 seconds | with end slide" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>
    </div>
  );
};

export default SectionVideo;
