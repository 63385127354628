import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import axios from '../../contains/axios';
import API_HOST from '../../config/config';
import { useAuth  } from '../../contains/AuthContext';
import * as $ from 'jquery';
import 'jquery-validation';

import Label from "components/Label/Label";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { Product, PRODUCTS } from "data/data";
import { Helmet } from "react-helmet-async";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Checkbox from "shared/Checkbox/Checkbox";
import Input from "shared/Input/Input";
import Radio from "shared/Radio/Radio";
import Select from "shared/Select/Select";
import ContactInfo from "./ContactInfo";
import PaymentMethod from "./PaymentMethod";
import ShippingAddress from "./ShippingAddress";
import { useCart } from '../../context/CartContext';
import EmptyBox from "shared/EmptyBox/EmptyBox";
import PageThankyou from "../../containers/PageThankyou/PageThankyou";


interface JQuery {
  $: any; // Replace with your types
}

interface country {
  id:number;
  name:string;
}

const CheckoutPage = () => {
  const { user, login } = useAuth();
  const { cart, addToCart, cartItemCount, removeFromCart, removeFromCartAllItem, calculateTotal, discountTotal, calculateTax , calculateGrandTotal, getVat, getShippingCharge, getShippingChargeId } = useCart();
  const navigate = useNavigate();
  const location = useLocation();

  const [phone, setPhone] = useState(user ? user.phone:'');
  const [couponCodeDetail, setCouponCodeDetail] = useState<any[]>([]);
  const [discountValue, setDiscountValue] = useState<number>(0);

  const [orderDetail, setOrderDetail] = useState<any[]>([]);

  const [phoneValue, setPhoneValue] = useState<any>((user ? user.phone_number:''));
  const [phoneMsg, setPhoneMsg] = useState<any>('');
  const [isValid, setIsValid] = useState<boolean>(true);
  const [ccavenueApproveDetail, setCcavenueApproveDetail] = useState([]);
  useEffect(() => {
   handleGlobalPayment()
  }, [ccavenueApproveDetail]);

  const handleInputChange = (val: any) => {
    setPhoneValue(val || '');
    setIsValid((!!val && isValidPhoneNumber((val || ''))));
    if(val == '')
    {
      setPhoneMsg('Phone Number is required');
    }
    else if((!!val && isValidPhoneNumber((val || ''))) == false)
    {
      setPhoneMsg('Please enter a valid number.');
    }
    else if(val != '' && ((!!val && isValidPhoneNumber((val || ''))) == true))
    {
      setPhoneMsg('');
    }    
  };

  var phoneNumberCheckCount = 0;
  const phoneNumberCheck = () => {
    setIsValid((!!phoneValue && isValidPhoneNumber(phoneValue || '')));
    if(phoneValue == '')
    {
      setPhoneMsg('Phone Number is required');
    }
    else if((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == false)
    {
      setPhoneMsg('Please enter a valid number.');
    }
    else if(phoneValue != '' && ((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == true))
    {
      setPhoneMsg('');
    }
    if(phoneNumberCheckCount == 0)
    {
      setPhoneMsg('');
    }
    phoneNumberCheckCount = phoneNumberCheckCount+1;
    return (!!phoneValue && isValidPhoneNumber((phoneValue || '')));
  }

  function checkPhone(value:any)
  {
    if(value != ""){
      const phone_result = (/^[0-9]{10}$/).test(value);         
      if(value.length <= 10 && !(/^[0-9]{11}$/).test(value))
      {
        setPhone(value);      
      }
      else
      {
        setPhone(phone);
      }
    }else{ 
      setPhone(value);
    }    
  }

  useEffect(() => {},[couponCodeDetail]);

  const [countries, setCountries] = useState([]);
  const [countryValue, setCountryValue] = useState(user ? user.country:'');
  useEffect(() => {    
    const fetchData = async () => { 
      try {
        axios.post(API_HOST+'/api/get-countries',{
          nopaginate : '1',
          status : '1',
          user_id:user?user.id:'0'
        })
        .then(response => {
          if(response.data.success === 0)
          {
            setCountries(response.data.data);
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });  
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();    
  }, []);

  const [errMsg, setErrMsg] = useState<any[]>([]);
  const [successMsg, setSuccessMsg] = useState<any[]>([]);

  const [errCouponCodeMsg, setErrCouponCodeMsg] = useState<any[]>([]);
  const [successCouponCodeMsg, setSuccessCouponCodeMsg] = useState<any[]>([]);

  const [errContactInfoMsg, setErrContactInfoMsg] = useState<any[]>([]);
  const [errShippingAddressMsg, setErrShippingAddressMsg] = useState<any[]>([]);
  const [errPaymentMethodMsg, setErrPaymentMethodMsg] = useState<any[]>([]);

  const formContactInfoRef= useRef<HTMLFormElement>(null);
  const formShippingAddressRef= useRef<HTMLFormElement>(null);
  const formPaymentMethodRef= useRef<HTMLFormElement>(null);
  const formDiscountFormRef = useRef<HTMLFormElement>(null);
  const [confirming , setConfirming ] = useState(false);

  useEffect(() => {
    if(parseInt(cartItemCount) > 0)
    {
    }
    else
    {
      if(orderDetail && (Object.keys(orderDetail).length == 0))
      {
        navigate('/');
      }
    }
  },[cartItemCount]);

  const [tabActive, setTabActive] = useState<
    "ContactInfo" | "ShippingAddress" | "PaymentMethod"
  >("ContactInfo");

  const [mothodActive, setMethodActive] = useState<
    "cod" | "internet_banking" | "paypal" | "Wallet" | "ccavenue"
  >("cod");

  // formDiscountFormRef
  useEffect(() => {
    if (formDiscountFormRef.current) {   
      setErrCouponCodeMsg([]);
      setSuccessCouponCodeMsg([]);
      ($ as any)(formDiscountFormRef.current).validate({
        rules: {
          discount_code: {
            required: true,
          },
        },
        messages: {
          discount_code: {
            required: 'Discount code is required',
          },
        },
        errorPlacement: function (error: any, element: any) {
          error.addClass('block p-1 text-[14px] text-[#fa826a]');          
          error.insertAfter(element.parent());
        },        
      });
    }
  }, []);

  const handleDiscountFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setErrCouponCodeMsg([]);
    setSuccessCouponCodeMsg([]);
    e.preventDefault();
    setCouponCodeDetail([]);
    if (formDiscountFormRef.current) {      
      if(formDiscountFormRef.current.checkValidity())
      {
        
          const formDiscountFormData = new FormData(formDiscountFormRef.current);
        try {          
          axios.post(API_HOST+'/api/coupon_read',formDiscountFormData)
          .then(response => {
            if(response.data.success === 0)
            {
              var get_discount = discountTotal(response.data.data);
              if(get_discount)
              if ((calculateTotal() - get_discount) > 0) {
                setCouponCodeDetail(response.data.data);
                var successArray: Array<any> = [];
                successArray["success" as any] = response.data.message;
                setSuccessCouponCodeMsg(successArray);
              }
              else
              {
                setCouponCodeDetail([]);
                var newArray: Array<any> = [];
                newArray['server_error' as any] = 'Price greater than discount, Please try again';
                setErrCouponCodeMsg(newArray);
              }
                              
            }
          })
          .catch(error => {
            var newArray: Array<any> = [];              
            if(!error?.response)
            {
              newArray["server_error" as any] = "No Server Response";
            }
            else if(error.response.data.success == 2)
            {
              if(typeof error.response.data.data == 'object' && error.response.data.data !== null && Object.keys(error.response.data.data).length > 0)
              {                  
                Object.keys(error.response.data.data).forEach((item:any, index:any) => {
                  newArray[item] = error.response.data.data[item].toString();
                });                  
              }
              else
              {
                newArray['server_error' as any] = error.response.data.message;
              }
            }
            else if(error.response?.status === 409 )
            {
              newArray["server_error" as any] = "Please try again!";
            }
            else{
              newArray['server_error' as any] = "Please try again!";
            }
            setErrCouponCodeMsg(newArray);
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    }
  }
  // ContactInfo
  useEffect(() => {
    if (formContactInfoRef.current) {      
      setErrContactInfoMsg([]);    
      ($ as any)(formContactInfoRef.current).validate({
        rules: {
          email: {
            required: true,
            email: true,
          },          
        },
        messages: {
          email: {
            required: 'Email is required',
            email: 'Invalid email address',
          },          
        },
        errorPlacement: function (error: any, element: any) {
          error.addClass('block text-right p-1 text-[14px] text-[#fa826a]');          
          if (element.attr("name") == "phone" ) {
          }
          else
          {
            error.insertAfter(element);
          }
        },
      });
    }
  }, []);


  const handleFormContactInfoSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrContactInfoMsg([]);
    phoneNumberCheck();
    if (formContactInfoRef.current) {      
      if(formContactInfoRef.current.checkValidity())
      {
        if(phoneNumberCheck() == true)
        {
          setTabActive("ShippingAddress");
          handleScrollToEl("ShippingAddress");
        }
      }
      else
      {
        phoneNumberCheck();
      }
    }
  }

  // ShippingAddress
  useEffect(() => {
    if (formShippingAddressRef.current) {      
      setErrShippingAddressMsg([]);      
      ($ as any)(formShippingAddressRef.current).validate({
        rules: {
          first_name: {
            required: true,
          },
          last_name: {
            required: true,
          },
          address: {
            required: true,
          },
          city: {
            required: true,
          },
          state: {
            required: true,
          },
          country: {
            required: true,
          },
          postal_code: {
            required: true,
          },
        },
        messages: {
          first_name: {
            required: 'First Name is required',
          },
          last_name: {
            required: 'Last Name is required',
          },
          address: {
            required: 'Address is required',
          },
          city: {
            required: 'City is required',
          },
          state: {
            required: 'State is required',
          },
          country: {
            required: 'Country is required',
          },
          postal_code: {
            required: 'Postal code is required',
          },          
        },
        errorPlacement: function (error: any, element: any) {
          error.addClass('block text-right p-1 text-[14px] text-[#fa826a]');          
          error.insertAfter(element);
        },
        submitHandler: function (form: HTMLFormElement) {
          setErrShippingAddressMsg([]);
          setTabActive("PaymentMethod");
          handleScrollToEl("PaymentMethod");
        },
      });      
    }
  }, []);

  // PaymentMethod
  useEffect(() => {
    if (formPaymentMethodRef.current) {      
      setErrPaymentMethodMsg([]);
      ($ as any).validator.addMethod("required_transaction_id", function(this: any,value:any, element:any) {
        if (formPaymentMethodRef.current) {
          if('internet_banking' == formPaymentMethodRef.current.payment_method.value)
          {
            return ((value == '') ? false : true);
          }
          else
          {
            return false;
          }
        }
      }, "");     
      ($ as any)(formPaymentMethodRef.current).validate({
        rules: {
          payment_method: {
            required:true,
          },
          transaction_id: {
            required_transaction_id: true,
          },
        },
        messages: {
          payment_method: {
            required: 'Payment method is required',
          },
          transaction_id: {
            required_transaction_id: 'Transaction id is required',
          },         
        },
        errorPlacement: function (error: any, element: any) {
          error.addClass('block text-right p-1 text-[14px] text-[#fa826a]');          
          error.insertAfter(element);
        },        
      });    
    }
  }, []);

  
  const handleOrderFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formPaymentMethodRef.current) {      
      if(formPaymentMethodRef.current.checkValidity())
      {
        setErrMsg([]);
        setSuccessMsg([]);
        if(phoneNumberCheck() == true)
        {
          const mergedFormData = new FormData();

          if(formContactInfoRef.current)
          {
            const formContactInfoData = new FormData(formContactInfoRef.current);            
            formContactInfoData.forEach((value, key) => {
              mergedFormData.append(key, value);
            });
          }
          if(formShippingAddressRef.current)
          {
            const formShippingAddressData = new FormData(formShippingAddressRef.current);
            formShippingAddressData.forEach((value, key) => {
              mergedFormData.append(key, value);
            });
          }
          if(formPaymentMethodRef.current)
          {
            const formPaymentMethodData = new FormData(formPaymentMethodRef.current);
            formPaymentMethodData.forEach((value, key) => {
              mergedFormData.append(key, value);
            });
          }

          if((isValidPhoneNumber((phoneValue || ''))) == true)
          {
            const phoneNumber = parsePhoneNumber(phoneValue);
            if (phoneNumber) {
              mergedFormData.append('phone', `${phoneNumber.number}`);
              mergedFormData.append('phone_country', `${phoneNumber.country}`);
            }
          }
          
          if(parseInt(cartItemCount) > 0)
          {
            cart.map((item: Product,index: number) => {
              mergedFormData.append(`item[${index}][id]`, `${item.id}`);
              mergedFormData.append(`item[${index}][name]`, `${item.name}`);
              mergedFormData.append(`item[${index}][price]`, `${item.price}`);
              mergedFormData.append(`item[${index}][quantity]`, `${item.quantity}`);
              mergedFormData.append(`item[${index}][product_has_attributes_id]`, `${((item?.selected_attribute?.['product_has_attributes_id' as any]) ? (item?.selected_attribute?.['product_has_attributes_id' as any]) : '')}`);
              mergedFormData.append(`item[${index}][product_attribute_id]`, `${((item?.selected_attribute?.['product_attribute_id' as any]) ? (item?.selected_attribute?.['product_attribute_id' as any]) : '')}`);
              mergedFormData.append(`item[${index}][product_attribute_value_id]`, `${((item?.selected_attribute?.['product_attribute_value_id' as any]) ? (item?.selected_attribute?.['product_attribute_value_id' as any]) : '')}`);
              mergedFormData.append(`item[${index}][attribute_price]`, `${((item?.selected_attribute?.['attribute_price' as any]) ? (item?.selected_attribute?.['attribute_price' as any]) : '')}`);
              mergedFormData.append(`item[${index}][product_has_color_id]`, `${((item?.selected_color?.id) ? (item?.selected_color?.id) : '')}`);
              mergedFormData.append(`item[${index}][product_color_id]`, `${((item?.selected_color?.product_color_id) ? (item?.selected_color?.product_color_id) : '')}`);
            
            });
          }
          mergedFormData.append('user_id',`${((user?.id) ? user?.id : '')}`);
        
          try {
            setConfirming(true);
            axios.post(API_HOST+'/api/add_order',mergedFormData)
            .then(response => {
              if(response.data.success === 0)
              {
                if (formPaymentMethodRef.current) {                
                  var successArray: Array<any> = [];
                  successArray["success" as any] = response.data.message;
                  // setSuccessMsg(successArray);   
                  // login(response.data.data.user);
                  // removeFromCartAllItem();
                  // setOrderDetail(response.data.data.order);

                  if(mothodActive != 'ccavenue'){
                    setSuccessMsg(successArray);   
                    login(response.data.data.user);
                    removeFromCartAllItem();
                    setOrderDetail(response.data.data.order);
                    setConfirming(false);
                  } 
                  else {
                    setCcavenueApproveDetail(response.data.data.order);
                  }
                  // window.location.href = '/account-my-order';                
                }
              } else {
                setConfirming(false);
              }
            })
            .catch(error => {
              var newArray: Array<any> = [];              
              if(!error?.response)
              {
                newArray["server_error" as any] = "No Server Response";
              }
              else if(error.response.data.success == 2)
              {
                if(typeof error.response.data.data == 'object' && error.response.data.data !== null && Object.keys(error.response.data.data).length > 0)
                {                  
                  Object.keys(error.response.data.data).forEach((item:any, index:any) => {
                    newArray[item] = error.response.data.data[item].toString();
                  });                  
                }
                else
                {
                  newArray['server_error' as any] = error.response.data.message;
                }
              }
              else if(error.response?.status === 409 )
              {
                newArray["server_error" as any] = "Your Order not placed.";
              }
              else{
                newArray['server_error' as any] = "Your Order not placed.";
              }
              setErrMsg(newArray);
              setConfirming(false);
            });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        else
        {
          setTabActive("ContactInfo");
          handleScrollToEl("ContactInfo");
        }
      }
    }
  }

  useEffect(() => {},[errMsg]);
  useEffect(() => {
    if(Object.keys(successMsg).length > 0)
    {
      setTimeout(function () {
        setSuccessMsg([]);
      }, 10000);
    }
  }, [successMsg]);

  useEffect(() => {},[errCouponCodeMsg]);
  useEffect(() => {
    if(Object.keys(successCouponCodeMsg).length > 0)
    {
      setTimeout(function () {
        setSuccessCouponCodeMsg([]);
      }, 10000);
    }
  }, [successCouponCodeMsg]);

  
  // paypal

  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");  
  const [orderId, setOrderId] = useState<string | null>(null);
  const [paypalOrderDetailGet, setPaypalOrderDetailGet] = useState([]);
  const [paypalOrderApproveDetail, setPaypalOrderApproveDetail] = useState([]);

  // creates a paypal order
  const createOrder = async (data: any, actions: any) => {
    try {      
      setPaypalOrderDetailGet([]);
      if (formPaymentMethodRef.current) {      
        if(formPaymentMethodRef.current.checkValidity())
        {
          setErrMsg([]);
          setSuccessMsg([]);
          const mergedFormData = new FormData();

          if(formContactInfoRef.current)
          {
            const formContactInfoData = new FormData(formContactInfoRef.current);            
            formContactInfoData.forEach((value, key) => {
              mergedFormData.append(key, value);
            });
          }
          if(formShippingAddressRef.current)
          {
            const formShippingAddressData = new FormData(formShippingAddressRef.current);
            formShippingAddressData.forEach((value, key) => {
              mergedFormData.append(key, value);
            });
          }
          if(formPaymentMethodRef.current)
          {
            const formPaymentMethodData = new FormData(formPaymentMethodRef.current);
            formPaymentMethodData.forEach((value, key) => {
              mergedFormData.append(key, value);
            });
          }
          
          if(parseInt(cartItemCount) > 0)
          {
            cart.map((item: Product,index: number) => {
              mergedFormData.append(`item[${index}][id]`, `${item.id}`);
              mergedFormData.append(`item[${index}][name]`, `${item.name}`);
              mergedFormData.append(`item[${index}][price]`, `${item.price}`);
              mergedFormData.append(`item[${index}][quantity]`, `${item.quantity}`);
              mergedFormData.append(`item[${index}][product_has_attributes_id]`, `${((item?.selected_attribute?.['product_has_attributes_id' as any]) ? (item?.selected_attribute?.['product_has_attributes_id' as any]) : '')}`);
              mergedFormData.append(`item[${index}][product_attribute_id]`, `${((item?.selected_attribute?.['product_attribute_id' as any]) ? (item?.selected_attribute?.['product_attribute_id' as any]) : '')}`);
              mergedFormData.append(`item[${index}][product_attribute_value_id]`, `${((item?.selected_attribute?.['product_attribute_value_id' as any]) ? (item?.selected_attribute?.['product_attribute_value_id' as any]) : '')}`);
              mergedFormData.append(`item[${index}][attribute_price]`, `${((item?.selected_attribute?.['attribute_price' as any]) ? (item?.selected_attribute?.['attribute_price' as any]) : '')}`);
            });
          }
          mergedFormData.append('user_id',`${((user?.id) ? user?.id : '')}`);
          
          try {
            return await axios.post(API_HOST+'/api/add_order',mergedFormData)
            .then(response => {
              if(response.data.success === 0)
              {
                setPaypalOrderDetailGet(response.data);
                if (formPaymentMethodRef.current) {
                  if('paypal' == formPaymentMethodRef.current.payment_method.value)
                  {
                    login(response.data.data.user);
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            currency_code: "USD",
                            value: (calculateTotal() - discountTotal(couponCodeDetail)),
                          },
                        },
                      ],
                    }).then((orderID:any) => {
                      setOrderId(response.data.data.order.id);
                      return orderID;
                    });                    
                  }
                  else
                  {
                    var successArray: Array<any> = [];
                    successArray["success" as any] = response.data.message;
                    setSuccessMsg(successArray);   
                    login(response.data.data.user);
                    removeFromCartAllItem();
                    setOrderDetail(response.data.data.order);
                    // window.location.href = '/account-my-order';
                  }
                }
              }
            })
            .catch(error => {
              var newArray: Array<any> = [];              
              if(!error?.response)
              {
                newArray["server_error" as any] = "No Server Response";
              }
              else if(error.response.data.success == 2)
              {
                if(typeof error.response.data.data == 'object' && error.response.data.data !== null && Object.keys(error.response.data.data).length > 0)
                {                  
                  Object.keys(error.response.data.data).forEach((item:any, index:any) => {
                    newArray[item] = error.response.data.data[item].toString();
                  });                  
                }
                else
                {
                  newArray['server_error' as any] = error.response.data.message;
                }
              }
              else if(error.response?.status === 409 )
              {
                newArray["server_error" as any] = "Your Order not placed.";
              }
              else{
                newArray['server_error' as any] = "Your Order not placed.";
              }
              setErrMsg(newArray);
            });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }   
      }
    } catch (error) {
      console.error('Error creating order:', error);
    }    
  };

  useEffect(() => {},[paypalOrderDetailGet]);

  // check Approval
  const onApprove = (data:any, actions:any) => {
    return actions.order.capture().then(function(details: any) {
      setPaypalOrderApproveDetail(details);
    });    
  };

  useEffect(() => {
    if(Object.keys(paypalOrderApproveDetail).length > 0)
    {
      if(paypalOrderApproveDetail?.['id' as any])
      {
        if(Object.keys(paypalOrderDetailGet).length > 0) 
        {
          try {
            axios.post(API_HOST+'/api/paypal_order_update',{
              user_id:paypalOrderDetailGet?.['data' as any]?.['user' as any]?.['id' as any],
              id : paypalOrderDetailGet?.['data' as any]?.['order' as any]?.['id' as any],
              transaction_id : paypalOrderApproveDetail?.['id' as any],
            })
            .then(response => {
              if(response.data.success === 0)
              {
                var successArray: Array<any> = [];
                successArray["success" as any] = response.data.message;
                setSuccessMsg(successArray);   
                // login(response.data.data);
                removeFromCartAllItem();
                setOrderDetail(response.data.data);
                // window.location.href = '/account-my-order'; 
              }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });  

          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
      }
    }
  },[paypalOrderApproveDetail]);  

  //capture likely error
  const onError = (data:any, actions:any) => {
      setErrorMessage("An Error occured with your payment ");
  };

  

    // paypal done

  const handleScrollToEl = (id: string) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element?.scrollIntoView({ behavior: "smooth" });
    }, 80);
  };

  const renderProduct = (item: Product, index: number) => {
    const { id, image, price, name, link, quantity, images_with_color, selected_color } = item;
    let selectedImage = '';
    if(selected_color){
      if(images_with_color?.find(item => item.color_id === selected_color.product_color_id)){
        selectedImage = images_with_color?.find(item => item.color_id === selected_color.product_color_id).image;
      } 
    }

    return (
      <div key={index} className="relative flex py-7 first:pt-0 last:pb-0">
        <div className="relative h-36 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          {selectedImage &&  <img
            src={selectedImage}
            alt={name}
            className="h-full w-full object-contain object-center"
          />}
           {!selectedImage &&  <img
            src={image}
            alt={name}
            className="h-full w-full object-contain object-center"
          />}
          <Link to={link} className="absolute inset-0"></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">
                  <Link className="line-clamp-2" to={link}>{name}</Link>
                </h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                  {/* <div className="flex items-center space-x-1.5">
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M7.01 18.0001L3 13.9901C1.66 12.6501 1.66 11.32 3 9.98004L9.68 3.30005L17.03 10.6501C17.4 11.0201 17.4 11.6201 17.03 11.9901L11.01 18.0101C9.69 19.3301 8.35 19.3301 7.01 18.0001Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.35 1.94995L9.69 3.28992"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.07 11.92L17.19 11.26"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 22H16"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.85 15C18.85 15 17 17.01 17 18.24C17 19.26 17.83 20.09 18.85 20.09C19.87 20.09 20.7 19.26 20.7 18.24C20.7 17.01 18.85 15 18.85 15Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span>{`Black`}</span>
                  </div> */}
                  {
                    item?.selected_attribute && Object.keys(item?.selected_attribute).length > 0 && (
                      <>
                      <div className="flex items-center space-x-1.5">
                        <span className="capitalize">{`${item?.selected_attribute['name' as any]} : `}
                        <span className="uppercase">{item?.selected_attribute['value' as any]}</span> </span>
                      </div>
                      <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                      </>
                    )
                  }
</div>
<div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                   {
                    item?.selected_color && Object.keys(item?.selected_color).length > 0 && (
                      <>
                      <div className="flex items-center space-x-1.5">
                        <span className="capitalize">Color</span>
                       
                      </div>
                      <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                      <div className="flex items-center space-x-1.5"> <span className="uppercase">{item?.selected_color?.name}</span> 
                      </div>
                      </>
                    )
                  }
                  <div className="flex items-center space-x-1.5">
                    {/* <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M21 9V3H15"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 15V21H9"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 3L13.5 10.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.5 13.5L3 21"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg> */}
</div>
</div>
<div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                    <span>Qty {quantity}</span>
                  
                </div>

                <div className="mt-3 flex-col sm:flex-row flex justify-between w-full sm:hidden relative">
                  <select
                    name="qty"
                    id="qty"
                    className="w-fit mb-2 sm:mb-0 sm:mr-2 form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                    defaultValue={quantity}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={((item?.selected_attribute?.['attribute_price' as any]) ? (item?.selected_attribute?.['attribute_price' as any]) : (price))}
                  />
                </div>
              </div>

              <div className="mr-2 hidden flex-1 sm:flex justify-end">
                <Prices price={quantity ? ((item?.selected_attribute?.['attribute_price' as any]) ? (item?.selected_attribute?.['attribute_price' as any]) : (price)) * quantity : 0} className="mt-0.5" discounted_price={quantity ? (item.discounted_price * quantity) : 0}/>
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            <div className="hidden sm:block text-center relative">
              <NcInputNumber className="relative z-10" defaultValue={quantity} max={item?.stock} item={item}/> 
            </div>

            {/* <a
              href="##"
              className="cart-remove-btn relative z-10 flex items-center mt-3 font-medium text-sm "
            >
              <span>Remove</span>
            </a> */}
            <button type="button" className="cart-remove-btn relative z-10 flex items-center mt-3 font-medium text-sm " onClick={() => removeFromCart(id)}>Remove</button>
          </div>
        </div>
      </div>
    );
  };

  const renderDebitCredit = () => {
    const active = mothodActive === "cod";
    return (
      <div className="flex items-start space-x-4 sm:space-x-6">
        <Radio
          className="pt-3.5"
          name="payment_method"
          id="cod"
          defaultChecked={active}
          onChange={(e) => setMethodActive(e as any)}
        />
        <div className="flex-1">
          <label
            htmlFor="cod"
            className="flex items-center space-x-4 sm:space-x-6"
          >
            <div
              className={`p-2.5 rounded-xl border-2 ${
                active
                  ? "border-slate-600 dark:border-slate-300"
                  : "border-gray-200 dark:border-slate-600"
              }`}
            >
              <svg
                className="w-6 h-6 sm:w-7 sm:h-7"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M2 12.6101H19"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19 10.28V17.43C18.97 20.28 18.19 21 15.22 21H5.78003C2.76003 21 2 20.2501 2 17.2701V10.28C2 7.58005 2.63 6.71005 5 6.57005C5.24 6.56005 5.50003 6.55005 5.78003 6.55005H15.22C18.24 6.55005 19 7.30005 19 10.28Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 6.73V13.72C22 16.42 21.37 17.29 19 17.43V10.28C19 7.3 18.24 6.55 15.22 6.55H5.78003C5.50003 6.55 5.24 6.56 5 6.57C5.03 3.72 5.81003 3 8.78003 3H18.22C21.24 3 22 3.75 22 6.73Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.25 17.8101H6.96997"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.10986 17.8101H12.5499"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p className="font-medium">Cash on Delivery</p>
          </label>

          {/* <div
            className={`mt-6 mb-4 space-y-3 sm:space-y-5 ${
              active ? "block" : "hidden"
            }`}
          >
            <div className="max-w-lg">
              <Label className="text-sm">Card number</Label>
              <Input className="mt-1.5" type={"text"} />
            </div>
            <div className="max-w-lg">
              <Label className="text-sm">Name on Card</Label>
              <Input className="mt-1.5" />
            </div>
            <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
              <div className="sm:w-2/3">
                <Label className="text-sm">Expiration date (MM/YY)</Label>
                <Input className="mt-1.5" placeholder="MM/YY" />
              </div>
              <div className="flex-1">
                <Label className="text-sm">CVC</Label>
                <Input className="mt-1.5" placeholder="CVC" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  };

  const renderInterNetBanking = () => {
    const active = mothodActive === "internet_banking";
    return (
      <div className="flex items-start space-x-4 sm:space-x-6">
        <Radio
          className="pt-3.5"
          name="payment_method"
          id="internet_banking"
          defaultChecked={active}
          onChange={(e) => setMethodActive(e as any)}
        />
        <div className="flex-1">
          <label
            htmlFor="internet_banking"
            className="flex items-center space-x-4 sm:space-x-6"
          >
            <div
              className={`p-2.5 rounded-xl border-2 ${
                active
                  ? "border-slate-600 dark:border-slate-300"
                  : "border-gray-200 dark:border-slate-600"
              }`}
            >
              <svg
                className="w-6 h-6 sm:w-7 sm:h-7"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.99998 3H8.99998C7.04998 8.84 7.04998 15.16 8.99998 21H7.99998"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 3C16.95 8.84 16.95 15.16 15 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p className="font-medium">Bank Transfer</p>
          </label>
          <div className={`mt-6 mb-4 ${active ? "block" : "hidden"}`}>
            <p className="text-sm dark:text-slate-300">
              Your order will be delivered to you after you transfer to this bank account and enter transaction id at below given box and confirm order:
            </p>
            <ul className="mt-3.5 text-sm text-slate-500 dark:text-slate-400 space-y-2">
              <li>
                <h3 className="text-base text-slate-800 dark:text-slate-200 font-semibold mb-1">
                Sparkle Luxury
                </h3>
              </li>
              <li>
                {" "}
                Bank name:{" "}
                <span className="text-slate-900 dark:text-slate-200 font-medium">
                ABC BANK
                </span>
              </li>
                <li>
                {" "}
                Account Name:{" "}
                <span className="text-slate-900 dark:text-slate-200 font-medium">
                Sparkle Luxury 
                </span>
              </li>
              
              <li>
                {" "}
                Account number:{" "}
                <span className="text-slate-900 dark:text-slate-200 font-medium">
                1234567890
                </span>
              </li>
              {/* <li>
                {" "}
                Sort code:{" "}
                <span className="text-slate-900 dark:text-slate-200 font-medium">
                  999
                </span>
              </li> */}
              <li>
                {" "}
                IFSC Code:{" "}
                <span className="text-slate-900 dark:text-slate-200 font-medium">
                ICIC0001
                </span>
              </li>
              {/* <li>
                {" "}
                BIC:{" "}
                <span className="text-slate-900 dark:text-slate-200 font-medium">
                  BIC/Swift
                </span>
              </li> */}
            </ul>
          </div>
          <div
            className={`mt-6 mb-4 space-y-3 sm:space-y-5 ${
              active ? "block" : "hidden"
            }`}
          >
            <div className="max-w-lg">
              <Label className="text-sm">Transaction Id</Label>
              <Input className="mt-1.5" id="transaction_id" name="transaction_id" required={( active ? true : false )} type={"text"} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPaypal = () => {
    const active = mothodActive === "paypal";
    return (
      <div className="flex items-start space-x-4 sm:space-x-6">
        <Radio
          className="pt-3.5"
          name="payment_method"
          id="paypal"
          defaultChecked={active}
          onChange={(e) => { setMethodActive(e as any); setShow(true);}}
        />
        <div className="flex-1">
          <label
            htmlFor="paypal"
            className="flex items-center space-x-4 sm:space-x-6"
          >
            <div
              className={`p-2.5 rounded-xl border-2 ${
                active
                  ? "border-slate-600 dark:border-slate-300"
                  : "border-gray-200 dark:border-slate-600"
              }`}
            >
              <svg
                className="w-6 h-6 sm:w-7 sm:h-7"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.99998 3H8.99998C7.04998 8.84 7.04998 15.16 8.99998 21H7.99998"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 3C16.95 8.84 16.95 15.16 15 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p className="font-medium">Paypal</p>
          </label>
          <div className={`mt-6 mb-4 ${active ? "block" : "hidden"}`}>
              <div>
                <PayPalButtons
                  createOrder={createOrder}
                  onApprove={onApprove}
                  style={{ layout: 'vertical' }}
                />
              </div>
          </div>          
        </div>
      </div>
    );
  };

  const renderWallet = () => {
    const active = mothodActive === "Wallet";
    return (
      // <div className="flex items-start space-x-4 sm:space-x-6">
      //   <Radio
      //     className="pt-3.5"
      //     name="payment-method"
      //     id="Wallet"
      //     defaultChecked={active}
      //     onChange={(e) => setMethodActive(e as any)}
      //   />
      //   <div className="flex-1">
      //     <label
      //       htmlFor="Wallet"
      //       className="flex items-center space-x-4 sm:space-x-6 "
      //     >
      //       <div
      //         className={`p-2.5 rounded-xl border-2 ${
      //           active
      //             ? "border-slate-600 dark:border-slate-300"
      //             : "border-gray-200 dark:border-slate-600"
      //         }`}
      //       >
      //         <svg
      //           className="w-6 h-6 sm:w-7 sm:h-7"
      //           viewBox="0 0 24 24"
      //           fill="none"
      //         >
      //           <path
      //             d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //           <path
      //             d="M2.5 12.4101V7.8401C2.5 6.6501 3.23 5.59006 4.34 5.17006L12.28 2.17006C13.52 1.70006 14.85 2.62009 14.85 3.95009V7.75008"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //           <path
      //             d="M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //           <path
      //             d="M7 12H14"
      //             stroke="currentColor"
      //             strokeWidth="1.5"
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //           />
      //         </svg>
      //       </div>
      //       <p className="font-medium">Google / Apple Wallet</p>
      //     </label>
      //     <div className={`mt-6 mb-4 space-y-6 ${active ? "block" : "hidden"}`}>
      //       <div className="text-sm prose dark:prose-invert">
      //         <p>
      //           Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque
      //           dolore quod quas fugit perspiciatis architecto, temporibus quos
      //           ducimus libero explicabo?
      //         </p>
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <>
      </>
    );
  };

  const renderCcaveue = () => {
    const active = mothodActive === "ccavenue";
    return (
      <div className="flex items-start space-x-4 sm:space-x-6">
        <Radio
          className="pt-3.5"
          name="payment_method"
          id="ccavenue"
          defaultChecked={active}
          onChange={(e) => { setMethodActive(e as any); setShow(true);}}
        />
        <div className="flex-1">
          <label
            htmlFor="ccavenue"
            className="flex items-center space-x-4 sm:space-x-6"
          >
            <div
              className={`p-2.5 rounded-xl border-2 ${
                active
                  ? "border-slate-600 dark:border-slate-300"
                  : "border-gray-200 dark:border-slate-600"
              }`}
            >
              <svg
                className="w-6 h-6 sm:w-7 sm:h-7"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.99998 3H8.99998C7.04998 8.84 7.04998 15.16 8.99998 21H7.99998"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 3C16.95 8.84 16.95 15.16 15 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p className="font-medium">Online Payment</p>
          </label>
          <div className={`mt-6 mb-4 ${active ? "block" : "hidden"}`}>
              <div id="mount-id">
              </div>
              {/* <button type="button" onClick={() => handleGlobalPayment()}>Pay</button> */}
          </div>          
        </div>
      </div>
    );
  };

  const handleGlobalPayment = () => {

    try {
          // const headers = {
          //   'Content-Type': 'application/vnd.ni-identity.v1+json',
          //   'Authorization': 'Bearer NmQ3NjM2ZWEtY2VjYS00MGMzLThkZWYtOTBiOTM5ZjIxZWI2Ojk0ZjZlMGRlLTk5MGUtNDJhMy1iNzI1LTdlMzFjOTJiZTVhNw=='
          // };
            const data = {
              // Your POST request data
              key1: 'value1',
              key2: 'value2'
            };
            axios.post(API_HOST+'/api/get-network-global-token',data
            )
            .then(response => {
              if(response.data.success === 0)
              {
                //call the order creation api
                let token = response.data.data.token;
                try {
                  const orderData = {
                    // Your POST request data
                    email: user?.email,
                    amount: ( (calculateTotal() - discountTotal(couponCodeDetail)) + parseFloat(calculateTax())) * 100,
                    token:token
                  };
                  axios.post(API_HOST+'/api/create-network-global-order',orderData
                  )
                  .then(response => {
                    if(response.data.success === 0)
                    {
                      let norder_id = response.data.data.reference;
                      let payment_link = response.data.data.payment_link;
                      try {
                        const networkData = {
                          // Your POST request data
                          network_global_order_id: norder_id,
                          user_id:user?.id,
                          id : ccavenueApproveDetail?.['id' as any],
                        };
                        axios.post(API_HOST+'/api/update-network-global-id',networkData
                        )
                        .then(response => {
                          if(response.data.success === 0)
                          {
                            window.location.href = payment_link;
                          }
                          })
                          .catch(error => {
                              console.error('Error fetching data:', error);
                          });
      
                      } catch (error) {
                        console.error('Error fetching data:', error);
                      }
                      //window.location.href = response.data.data.payment_link;
                    }
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });

                } catch (error) {
                  console.error('Error fetching data:', error);
                }

                // window.location.href = '/account-my-order'; 
              }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });  

          } catch (error) {
            console.error('Error fetching data:', error);
          }
    // Dynamically add a script tag to load the third-party JavaScript file
  }

  const renderLeft = () => {
    return (
      <div className="space-y-8">
        <div id="ContactInfo" className="scroll-mt-24">          
          <div className="border border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden z-0">
            <div className="flex flex-col sm:flex-row items-start p-6 ">
              <span className="hidden sm:block">
                <svg
                  className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.50998C8.71997 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <div className="sm:ml-8">
                <h3 className=" text-slate-700 dark:text-slate-300 flex ">
                  <span className="uppercase tracking-tight">CONTACT INFO</span>
                  {(tabActive === "ShippingAddress" || tabActive === "PaymentMethod") && (
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2.5"
                    stroke="currentColor"
                    className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  )}
                </h3>
                { user && 
                  <div className="font-semibold mt-1 text-sm">
                  <span className="">{user.name}</span>
                  <span className="ml-3 tracking-tighter">{user ? user.phone_number:''}</span>
                  </div>
                }
               
              </div>
              {/*<ButtonSecondary type="button"
                sizeClass="py-2 px-4 "
                fontSize="text-sm font-medium"
                className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
                onClick={() => {
                  setTabActive("ContactInfo");
                  handleScrollToEl("ContactInfo");
                }}
              >
                Change
              </ButtonSecondary>*/}
            </div>
            <form ref={formContactInfoRef} onSubmit={handleFormContactInfoSubmit} encType="multipart/form-data" >
              <div className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${(tabActive === "ContactInfo") ? "block" : "hidden"}`}>
                <div className="flex justify-between flex-wrap items-baseline">
                  <h3 className="text-lg font-semibold">Contact infomation</h3>
                  {!user &&
                  <span className="block text-sm my-1 md:my-0">
                    Do not have an account?{` `}
                    <a href="/login" className="text-primary-500 font-medium">
                      Log in
                    </a>
                  </span>
                  }
                </div>
                {/*<div className="max-w-lg">
                  <Label className="text-sm">Your phone number</Label>
                  <Input className="mt-1.5" id="phone" name="phone" type="tel" onChange={(e) => checkPhone(e.target.value)} defaultValue={phone} />
                </div>*/}
                <div className="max-w-lg">
                  <Label className="text-sm">Your phone number</Label>
                  <PhoneInput
                    inputclass={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 h-12`}
                    international={false}
                    defaultCountry="IN"
                    defaultValue={phoneValue}
                    value={phoneValue}
                    onChange={handleInputChange}
                    
                  />
                  {(phoneMsg) && <label id="" className={` block p-1 text-[14px] text-[#fa826a]`} >{phoneMsg}</label>}
                </div>
                <div className="max-w-lg">
                  <Label className="text-sm">Email address</Label>
                  <Input className="mt-1.5" type={"email"} id="email" name="email" defaultValue={user ? user.email:''}/>
                </div>
                <div>
                  <Checkbox
                    className="!text-sm"
                    name="uudai"
                    label="Email me news and offers"
                    defaultChecked
                  />
                </div>

                {/* ============ */}
                <div className="flex flex-col sm:flex-row pt-6">
                  <ButtonPrimary type="submit"
                    className="sm:!px-7 shadow-none"
                  >
                    Save and next to Shipping
                  </ButtonPrimary>
                  {/*<ButtonSecondary type="button"
                    className="mt-3 sm:mt-0 sm:ml-3"
                    onClick={() => {
                      setTabActive("ShippingAddress");
                      handleScrollToEl("ShippingAddress");
                    }}
                  >
                    Cancel
                  </ButtonSecondary>*/}
                </div>
              </div>
            </form>
          </div>
        </div>

        <div id="ShippingAddress" className="scroll-mt-24">
          {/*<ShippingAddress
            isActive={tabActive === "ShippingAddress"}
            onOpenActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
            onCloseActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
          />*/}

          <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
            <div className="p-6 flex flex-col sm:flex-row items-start">
              <span className="hidden sm:block">
                <svg
                  className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>

              <div className="sm:ml-8">
                <h3 className=" text-slate-700 dark:text-slate-300 flex ">
                  <span className="uppercase">SHIPPING ADDRESS</span>
                  {(tabActive === "PaymentMethod") && (
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2.5"
                    stroke="currentColor"
                    className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  )}
                </h3>
                <div className="font-semibold mt-1 text-sm">
                { user && 
                  <span className="">
                   {user.address ? user.address : ''}
                  </span>
                }
                </div>
              </div>
              {/*<ButtonSecondary type="button"
                sizeClass="py-2 px-4 "
                fontSize="text-sm font-medium"
                className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
                onClick={() => {
                  setTabActive("ShippingAddress");
                  handleScrollToEl("ShippingAddress");
                }}
              >
                Change
              </ButtonSecondary>*/}
            </div>
            <form ref={formShippingAddressRef} encType="multipart/form-data" >
              <div className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${(tabActive === "ShippingAddress") ? "block" : "hidden"}`}>
                {/* ============ */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                  <div>
                    <Label className="text-sm">First name</Label>
                    <Input className="mt-1.5" id="first_name" name="first_name" type="text" defaultValue={user? user.name.split(' ')[0] : ''} />
                  </div>
                  <div>
                    <Label className="text-sm">Last name</Label>
                    <Input className="mt-1.5" id="last_name" name="last_name" type="text" defaultValue={user? user.name.split(' ')[1] : ''} />
                  </div>
                </div>

                {/* ============ */}
                <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
                  <div className="flex-1">
                    <Label className="text-sm">Address</Label>
                    <Input
                      id="address" name="address"
                      className="mt-1.5"
                      placeholder=""
                      defaultValue={user ? user.address :  ''}
                      type={"text"}
                    />
                  </div>
                  {/* <div className="sm:w-1/3">
                    <Label className="text-sm">Apt, Suite *</Label>
                    <Input className="mt-1.5" defaultValue="" />
                  </div> */}
                </div>

                {/* ============ */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                  <div>
                    <Label className="text-sm">City</Label>
                    <Input className="mt-1.5" type="text" id="city" name="city" defaultValue={user?user.city:''} />
                  </div>
                  <div>
                    <Label className="text-sm">State/Province</Label>
                    <Input className="mt-1.5" type="text" id="state" name="state" defaultValue={user?user.state:''} />
                  </div>
                  
                </div>

                {/* ============ */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                  <div>
                    <Label className="text-sm">Country</Label>
                    <Select id="country" name="country" className="mt-1.5" defaultValue={countryValue} value={countryValue} onChange={(e) => setCountryValue(e.target.value)} >
                    <option value="">Select Country</option>
                    {countries && countries.map((item:country, index) => (
                      <option key={index} value={item.id}>{item.name}</option>
                    ))}
                    </Select>
                  </div>
                  <div>
                    <Label className="text-sm">Postal code</Label>
                    <Input className="mt-1.5" type="text" id="postal_code" name="postal_code" defaultValue={user?user.postal_code:''} />
                  </div>
                </div>

                {/* ============ */}
                {/* <div>
                  <Label className="text-sm">Address type</Label>
                  <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
                    <Radio
                      label={`<span class="text-sm font-medium">Home <span class="font-light">(All Day Delivery)</span></span>`}
                      id="Address-type-home"
                      name="Address-type"
                      defaultChecked
                    />
                    <Radio
                      label={`<span class="text-sm font-medium">Office <span class="font-light">(Delivery <span class="font-medium">9 AM - 5 PM</span>)</span> </span>`}
                      id="Address-type-office"
                      name="Address-type"
                    />
                  </div>
                </div> */}

                {/* ============ */}
                <div className="flex flex-col sm:flex-row pt-6">
                  <ButtonPrimary type="submit"
                    className="sm:!px-7 shadow-none"                    
                  >
                    Save and next to Payment
                  </ButtonPrimary>
                  <ButtonSecondary type="button"
                    className="mt-3 sm:mt-0 sm:ml-3"
                    onClick={() => {
                      setTabActive("ContactInfo");
                      handleScrollToEl("ContactInfo");
                    }}
                  >
                    Back
                  </ButtonSecondary>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div id="PaymentMethod" className="scroll-mt-24">
          {/*<PaymentMethod
            isActive={tabActive === "PaymentMethod"}
            onOpenActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
            onCloseActive={() => setTabActive("PaymentMethod")}
          />*/}

          <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
            <div className="p-6 flex flex-col sm:flex-row items-start">
              <span className="hidden sm:block">
                <svg
                  className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.92969 15.8792L15.8797 3.9292"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.1013 18.2791L12.3013 17.0791"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.793 15.5887L16.183 13.1987"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.60127 10.239L10.2413 3.599C12.3613 1.479 13.4213 1.469 15.5213 3.569L20.4313 8.479C22.5313 10.579 22.5213 11.639 20.4013 13.759L13.7613 20.399C11.6413 22.519 10.5813 22.529 8.48127 20.429L3.57127 15.519C1.47127 13.419 1.47127 12.369 3.60127 10.239Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2 21.9985H22"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <div className="sm:ml-8">
                <h3 className=" text-slate-700 dark:text-slate-400 flex ">
                  <span className="uppercase tracking-tight">PAYMENT METHOD</span>
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2.5"
                    stroke="currentColor"
                    className="w-5 h-5 ml-3 text-slate-900"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </h3>
                {/* <div className="font-semibold mt-1 text-sm">
                  <span className="">Google / Apple Wallet</span>
                  <span className="ml-3">xxx-xxx-xx55</span>
                </div> */}
              </div>
              {/*<ButtonSecondary type="button"
                sizeClass="py-2 px-4 "
                fontSize="text-sm font-medium"
                className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
                onClick={() => {
                  setTabActive("PaymentMethod");
                  handleScrollToEl("PaymentMethod");
                }}
              >
                Change
              </ButtonSecondary>*/}
            </div>

            <form ref={formPaymentMethodRef} onSubmit={handleOrderFormSubmit} encType="multipart/form-data" >
              <div className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-6 ${(tabActive === "PaymentMethod") ? "block" : "hidden"}`}>
                {/* ==================== */}
                <div>{renderDebitCredit()}</div>
                
                {/* ==================== */}
                {/* <div>{renderPaypal()}</div> */}

                {/* ==================== */}
                {/* <div>{renderInterNetBanking()}</div> */}

                {/* <div>{renderCcaveue()}</div> */}

                {/* ==================== */}
                <div>{renderWallet()}</div>
                <Input className="mt-1.5" type="hidden" id="tax" name="tax" defaultValue={calculateTax()} />
                <Input className="mt-1.5" type="hidden" id="shipping_id" name="shipping_id" defaultValue={getShippingChargeId()} />
                <Input className="mt-1.5" type="hidden" id="sub_amount" name="sub_amount" defaultValue={calculateTotal()} />
                <Input className="mt-1.5" type="hidden" id="amount" name="amount" defaultValue={calculateGrandTotal()} />
                <Input className="mt-1.5" type="hidden" id="coupon" name="coupon" defaultValue={discountTotal(couponCodeDetail)} />
                <Input className="mt-1.5" type="hidden" id="promocode_id" name="promocode_id" defaultValue={(couponCodeDetail["id" as any])} />
                <div className="flex pt-6">
                  {/* {parseInt(cartItemCount) > 0 && (
                    <ButtonPrimary type="submit" 
                      className={`w-full max-w-[240px] ${(mothodActive === 'paypal') ? 'hidden' : ''}`}
                    >
                      Confirm order
                    </ButtonPrimary>
                  )} */}
                  {!confirming && parseInt(cartItemCount) > 0 && (
                    <ButtonPrimary type="submit" 
                      className={`w-full max-w-[240px] ${(mothodActive === 'paypal') ? 'hidden' : ''}`}
                    >
                      Confirm order
                    </ButtonPrimary>
                  )}
                  {confirming && (
                    <ButtonPrimary type="button" 
                      className={`w-full max-w-[240px] ${(mothodActive === 'paypal') ? 'hidden' : ''}`}
                    >
                      Processing...
                    </ButtonPrimary>
                  )}
                  <ButtonSecondary type="button" className="ml-3" onClick={() => {
                    setTabActive("ShippingAddress");
                    handleScrollToEl("ShippingAddress");
                  }} >
                    Back
                  </ButtonSecondary>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const paypalOptions = {
    clientId: 'AfVK2pGGFzUUQSZivvmef9y-G0pc7KGhlrjCyP7twW1EaTIoNjT5RvozlID0tg0dWVPO4ouVPTqCReVQ',
    currency: 'USD',
    components: 'buttons',
  };

  return (
    <div className="nc-CheckoutPage">
      <Helmet>
        <title>Checkout || Sparkle Luxury</title>
      </Helmet>
      {
        orderDetail && (Object.keys(orderDetail).length > 0) && (
          <PageThankyou data={orderDetail} />
        )
      }
      {
        orderDetail && (Object.keys(orderDetail).length == 0) && (      
          <main className="container py-16 lg:pb-28 lg:pt-20 ">
            <div className="mb-16">
              <h2 className="text-custom-golden block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
                Checkout
              </h2>
              <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
                <Link to={"/"} className="">
                  Homepage
                </Link>
                <span className="text-xs mx-1 sm:mx-1.5">/</span>          
                <span className="underline">Checkout</span>
              </div>
            </div>

            <div className="mb-3">
              {
                (Object.keys(errMsg).length > 0) && (
                  <div role="alert">
                    <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                      Error
                    </div>
                    <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                      {
                        Object.keys(errMsg).map((item:any,index:any) => (
                          <p key={index}>{errMsg[item]}</p>
                        ))
                      }
                    </div>
                  </div>
                )
              }
              {
                (Object.keys(successMsg).length > 0) && (
                  <div role="alert">
                    <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2">
                      Success
                    </div>
                    <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700">
                      {
                        Object.keys(successMsg).map((item:any,index:any) => (
                          <p key={index}>{successMsg[item]}</p>
                        ))
                      }
                    </div>
                  </div>
                )
              }
            </div>
            <div className="flex flex-col lg:flex-row">
            
              <div className="flex-1">            
                {renderLeft()}
              </div>
              <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>

              <div className="w-full lg:w-[36%] ">
                <h3 className="text-lg font-semibold">Order summary</h3>
                <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
                  {cart.map(renderProduct)}
                </div>

                <h3 className="text-lg font-semibold">
                </h3>
                <div className="mt-10 pt-6 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">
                  <div className="mb-3">
                    {
                      (Object.keys(errCouponCodeMsg).length > 0) && (
                        <div role="alert">
                          <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                            Error
                          </div>
                          <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                            {
                              Object.keys(errCouponCodeMsg).map((item:any,index:any) => (
                                <p key={index}>{errCouponCodeMsg[item]}</p>
                              ))
                            }
                          </div>
                        </div>
                      )
                    }
                    {
                      (Object.keys(successCouponCodeMsg).length > 0) && (
                        <div role="alert">
                          <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2">
                            Success
                          </div>
                          <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700">
                            {
                              Object.keys(successCouponCodeMsg).map((item:any,index:any) => (
                                <p key={index}>{successCouponCodeMsg[item]}</p>
                              ))
                            }
                          </div>
                        </div>
                      )
                    }
                  </div>
                  <form ref={formDiscountFormRef} onSubmit={handleDiscountFormSubmit} encType="multipart/form-data" >              
                  <div>
                    <Label className="text-sm">Discount code</Label>
                    <div className="flex mt-1.5">
                      <Input sizeClass="h-10 px-4 py-3" id="discount_code" name="discount_code" type="text" required className="flex-1" />

                      <button type="submit" className="text-neutral-700 dark:text-neutral-200 border border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 rounded-2xl px-4 ml-3 font-medium text-sm bg-neutral-200/70 dark:bg-neutral-700 dark:hover:bg-neutral-800 w-24 flex justify-center items-center transition-colors">
                        Apply
                      </button>
                    </div>
                  </div>
                  </form>
                  <div className="mt-4 flex justify-between py-2.5">
                    <span>Subtotal</span>
                    <span className="font-semibold text-slate-900 dark:text-slate-200">
                    INR {calculateTotal() && !calculateTotal().isNaN ? calculateTotal() : 0.00}
                    </span>
                  </div>
                  {
                    (discountTotal(couponCodeDetail) > 0) && (                
                      <div className="flex justify-between py-2.5">
                        <span>Discount</span>
                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                        INR {discountTotal(couponCodeDetail)}
                        </span>
                      </div>
                    )
                  }

                  <div className="flex justify-between py-2.5">
                    <span>Shipping estimate</span>
                    <span className="font-semibold text-slate-900 dark:text-slate-200">
                      INR {getShippingCharge() && !getShippingCharge().isNaN ? getShippingCharge() : 0.00}
                    </span>
                  </div>
                  {/* <div className="flex justify-between py-2.5">
                    <span>Tax estimate ({getVat()} %)</span>
                    <span className="font-semibold text-slate-900 dark:text-slate-200">
                      INR {calculateTax() && !calculateTax().isNaN ? calculateTax() : 0.00}
                    </span>
                  </div> */}
                  <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                    <span>Order total</span>
                    <span>INR {(calculateGrandTotal() - discountTotal(couponCodeDetail))?.toFixed(2)}</span>
                  </div>
                </div>
                {/*<ButtonPrimary type="button" href="/account-my-order" className="mt-8 w-full">
                  Confirm order
                </ButtonPrimary>*/}            
                <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
                  <p className="block relative pl-5">
                    <svg
                      className="w-4 h-4 absolute -left-1 top-0.5"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 8V13"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.9945 16H12.0035"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Learn more{` `}
                    {/* <a
                      //target="_blank"
                      rel="noopener noreferrer"
                      href="javascript:void(0);"
                      className="text-slate-900 dark:text-slate-200 underline font-medium"
                    >
                      Taxes
                    </a>
                    <span>
                      {` `}and{` `}
                    </span> */}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="/payment-policy"
                      className="text-slate-900 dark:text-slate-200 underline font-medium"
                    >
                      Shipping
                    </a>
                    {` `} infomation
                  </p>
                </div>
              </div>
            </div>
          </main>
        )
      }
    </div>
  );
};

export default CheckoutPage;
