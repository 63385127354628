import React, { FC } from "react";
import { Link } from "react-router-dom";

interface SocialIconProps {
  className?: string;
  additionalProp?: any; // Change `any` to the appropriate type
}

const Socialicon: FC<SocialIconProps> = (props) => {
  const { className, additionalProp } = props;

  const Socialicons = [
    {
      SocialItemLink: "https://www.facebook.com/sparkleluxury.in",
      SocialItemClass: "fb-item",
      SocialItemFaClass: "fa-facebook",
    },
    {
      SocialItemLink: "https://www.instagram.com/sparkleluxury.in?igsh=MXF2N2xiZmJlaThoaA==",
      SocialItemClass: "insta-item",
      SocialItemFaClass: "fa-instagram",
    },
    // {
    //   SocialItemLink: "javascript:void(0);",
    //   SocialItemClass: "li-item",
    //   SocialItemFaClass: "fa-twitter",
    // },
    // {
    //   SocialItemLink: "/",
    //   SocialItemClass: "whatsapp-item",
    //   SocialItemFaClass: "fa-whatsapp",
    // },
    // {
    //   SocialItemLink: "https://www.youtube.com/@winprofx/",
    //   SocialItemClass: "youtube-item",
    //   SocialItemFaClass: "fa-youtube-play",
    // },
  ];

  return (
    <>
      {/* <div className={`social-lable ${className}`}>Follow Us On</div> */}
      <div className={`social-icon ${className}`}>
        {Socialicons.map((item, index) => (
          <Link key={index} className={`si-item ${item.SocialItemClass}`} to={item.SocialItemLink}>
            <span className="si-icon">
              <i className={`fa ${item.SocialItemFaClass}`} aria-hidden="true"></i>
            </span>
          </Link>
        ))}
      </div>
    </>
  );
};

export default Socialicon;
