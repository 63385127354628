import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import offer from "../images/banner/5758817.jpg";
import axios from 'axios';
import API_HOST from 'config/config';

const OfferPopup = () => {
    const [offerPopupLink,setOfferPopupLink] = useState('');
    const [offerPhoto,setOfferPhoto] = useState('');

    useEffect(() => {
      const fetchSettings = async () => { 
        try {
          axios.post(API_HOST+'/api/settings',{
          })
          .then(response => {
            if(response.data.success === 0)
            {
                setOfferPopupLink(response.data.data.offer_photo_link)
                setOfferPhoto(response.data.data.offer_photo_url)
            }
          })
          .catch(error => {
              console.error('Error fetching data:', error);
          });  
    
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchSettings();
    }, []);
    // State to track whether to add active class or not
    const [isActive, setIsActive] = useState(false);

    // State to track whether the popup is closed
    const [isClosed, setIsClosed] = useState(false);

    // Effect to add active class after 5 seconds
    useEffect(() => {
        const hasPopupBeenShown = localStorage.getItem('hasPopupBeenShown');
        if (!hasPopupBeenShown) {
            const timer = setTimeout(() => {
                setIsActive(true);
            }, 5000);
            localStorage.setItem('hasPopupBeenShown', '1');
            return () => clearTimeout(timer);
        }
       // Clear the timer to avoid memory leaks
        
    }, []); // Empty dependency array to run this effect only once

    // Function to handle closing the popup
    const handleClose = () => {
        setIsClosed(true);
        setIsActive(false);
    };

    // Render nothing if the popup is closed
    if (isClosed) {
        return null;
    }

    return (
        <>
        {offerPopupLink && <div className={`offer-popupbx ${isActive ? 'active' : ''}`}>
            <div className="offer-popupbx-inner">

                <Link to={offerPopupLink} >
                    <img src={offerPhoto} alt="Offer" />
                </Link>

                <div className="closebutton" onClick={handleClose} style={{cursor: "pointer"}}> 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>
            <div className={`overlayer ${isActive ? 'active' : ''}`}></div>
        </div>}
        </>
    );
};

export default OfferPopup;
