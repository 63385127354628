import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const PageFaqs: FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const questions = [
    {
      question: "Q1. What is imitation jewelry?",
      answer: "Imitation jewelry is jewelry that is made to look like real jewelry but is made from less expensive materials. It is a great option for those who want to enjoy the look of fine jewelry without the high price tag.",
    },
    {
      question: "Q2. What materials are used to make your imitation jewelry?",
      answer: "Our imitation jewelry is made from high-quality materials, including cubic zirconia, crystals, rhinestones, and high-quality metal alloys that are nickel-free and hypoallergenic.",
    },
    {
      question: "Q3. Is your imitation jewelry hypoallergenic?",
      answer: "Yes, all of our imitation jewelry is nickel-free and hypoallergenic, making it safe for those with sensitive skin to wear.",
    },
    {
      question: "Q4.How do I care for my imitation jewelry?",
      answer: "We recommend storing your imitation jewelry in a cool, dry place when not in use, and avoiding exposure to water, perfume, and other chemicals. You can clean your jewelry with a soft, dry cloth or with a mild soap and water solution.",
    },
    {
      question: "Q5. Do you offer a warranty on your imitation jewelry??",
      answer: "Yes, we offer a 2 day warranty on all of our imitation jewelry. If you receive a defective product or if your jewelry breaks within 2 days of purchase, we will replace it or provide a refund.",
    },
    // {
    //   question: "Q7. How can I contact customer support?",
    //   answer: (
    //     <div>
    //       For any questions or assistance, you can reach our customer support team at{" "}
    //       <a href="mailto:support@sparkleluxury.in">support@sparkleluxury.in</a> or{" "}
    //       <a href="tel:+918347578972 ">+91 8347578972 </a>.
    //     </div>
    //   ),
    // },
    {
      question: "Q7. Can I return or exchange my imitation jewelry?",
      answer: "Yes, we accept returns and exchanges within 2 days of purchase for unused and undamaged products. Please see our Returns and Refunds policy for more information.",
    },
    {
      question: "Q8. How do I know what size to order?",
      answer: "Most of our jewelry items come in one size, but some items have adjustable chains or bands. We include measurements in the product description to help you determine the size.",
    },
    {
      question: "Q9. Do you offer gift wrapping?",
      answer: "Yes, we offer gift wrapping for an additional fee. You can select the gift-wrapping option at checkout.",
    },
  ];

  const handleAccordionClick = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      <Helmet>
        <title>Frequently asked questions || Sparkle Luxury</title>
      </Helmet>
      <div className="breadcrumb-main-bx">
          <div className="lagle-container">
              <div className="breadcrumb-bx">
                  <Link className="breadcrumb-link" to="/">Home</Link>
                  <div className="breadcrumb-link breadcrumb-active">/ Frequently asked questions</div>
              </div>
          </div>
      </div>
      <section className="pt-16 pb-16">
        <div className="lagle-container">
          <div className="lagle-heading">Frequently asked questions</div>

            <div className="legal-content">
              <p>Thank you for considering our FAQs at Sparkle Luxury. Here are some frequently asked questions and answers about our imitation jewelry:</p>
            </div>

          <div className="faqs-accordion">
            {questions.map((item, index) => (
              <div className="acc-item" key={index}>
                <div
                  className={`acc-heding ${ openIndex === index ? "" : "active"}`}
                  onClick={() => handleAccordionClick(index)}
                >
                  {item.question}
                  <span className="down-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6`}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                    </svg>
                  </span>
                </div>

                <div
                  className={`acc-data ${openIndex === index ? "" : "active"}`}
                >
                  <div className="faqs-data">{item.answer}</div>
                </div>
              </div>
            ))}
          </div>
          
          <div className="legal-content">
            <p className="mt-4">If you have any questions or concerns about our Privacy Policy, please contact us at <a href="tel:+918347578972 ">+91 8347578972</a></p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PageFaqs;
