import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";


const PagePrivacy: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Privacy Policy || Sparkle Luxury</title>
      </Helmet>
      <div className="breadcrumb-main-bx">
          <div className="lagle-container">
              <div className="breadcrumb-bx">
                  <Link className="breadcrumb-link" to="/">Home</Link>
                  <div className="breadcrumb-link breadcrumb-active">/ Privacy Policy</div>
              </div>
          </div>
      </div>
      <section className="pt-16 pb-16">
          <div className="lagle-container">
              <div className="lagle-heading">Privacy Policy</div>
              <div className="legal-content">
                
                <p>Thank you for considering our Privacy Policy at Sparkle Luxury. The privacy and security of our customer's personal information are of utmost importance to us. In this policy, we will explain what personal data we collect, why we collect it, how we use it, and how we protect it.</p>

                <div className="main-sub-lable">1. Information we collect</div>
                <p>We collect personal information when you create an account with us, place an order, or sign up for our newsletter. The personal information we collect may Include your name, address, phone number, email address, and payment information.</p>

                <div className="main-sub-lable">2. How We Use Your Information</div>
                <p>We use your personal information to fulfill your orders, communicate with you about your orders, provide customer service, send you promotional materials, and improve our website and services.</p>

                <div className="main-sub-lable">3. Sharing Your Information</div>
                <p>We do not sell or rent your personal information to third parties. We may share your personal information with service providers who help us fulfill orders or perform other functions necessary to run our business.</p>

                <div className="main-sub-lable">4. Security Measures</div>
                <p>We take appropriate security measures to protect your personal information from unauthorized access, disclosure, or alteration. We use secure servers, firewalls, and SSL encryption to protect your data.</p>

                <div className="main-sub-lable">5. Cookies</div>
                <p>We use cookies to improve your experience on our website. Cookies are small data files that are stored on your device when you visit our website. You can disable cookies in your browser settings if you prefer not to have them.</p>

                <div className="main-sub-lable">6. Children's Privacy</div>
                <p>Our website is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under the age of 13.</p>

                <div className="main-sub-lable">7. Changes to Our Privacy Policy</div>
                <p>We reserve the right to modify this Privacy Policy at any time. If we make significant changes, we will notify you by email or by posting a notice on our website.</p>

                <p>If you have any questions or concerns about our Privacy Policy, please contact us at <a href="tel:+918347578972 ">+91 8347578972</a></p>

              </div>
          </div>
      </section>

  
    </div>
  );
};

export default PagePrivacy;
