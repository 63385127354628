import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const PagePaymentPolicy: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Payment Policy || Sparkle Luxury</title>
      </Helmet>
      <div className="breadcrumb-main-bx">
          <div className="lagle-container">
              <div className="breadcrumb-bx">
                  <Link className="breadcrumb-link" to="/">Home</Link>
                  <div className="breadcrumb-link breadcrumb-active">/ Payment Policy</div>
              </div>
          </div>
      </div>
      <section className="pt-16 pb-16">
          <div className="lagle-container">
              <div className="lagle-heading">Payment Policy</div>
              <div className="legal-content">


<div className="main-sub-lable">What are the various modes of payments at sparkleluxury.in?</div>
<p>We accept payment via the following methods:</p>
<ul className="legal-ul">
  <li>Credit Card</li>
  <li>Debit Card</li>
  <li>Net Banking</li>
  <li>E-Wallets</li>
  <li>PayPal </li>
  <li>Razor Pay</li>
  <li>Most International Credit And Debit</li>
</ul>

<div className="main-lable">Delivery & Shipping</div>

<div className="main-sub-lable">What kind of Delivery services does sparkleluxury.in provide?</div>
<p>sparkleluxury.in provides complimentary domestic and international shipping.</p>

<div className="main-sub-lable">What countries does sparkleluxury.in ship to?</div>
<p>sparkleluxury.in offers worldwide shipping to most jurisdictions.</p>

<div className="main-sub-lable">How much time will it take for my purchase to be delivered?</div>
<p>The approximate delivery time will differ with each order. Domestic orders will take about 2-4 business days for delivery from the dispatch date. International orders take about 10-12 days for delivery from the date of dispatch. Sometimes, delivery timelines are subject to beyond our control due to unexpected disruptions. We assure you that we will keep you informed at every step.</p>

<div className="main-sub-lable">Can I modify my delivery address once the order has been assigned?</div>
<p>Once the order has been assigned, you can change the delivery address until the item has been shipped. However, you should contact our customer care team, who will be able to assist further.</p>


<div className="main-sub-lable">Will there be additional taxes payable on my purchase?</div>
<p>For domestic clients, product prices displayed are inclusive of all taxes. For international clients, the product prices are exclusive to all import duties. As the recipient, you are liable for all import duties, customs, and local sales taxes imposed by the country you are in. payment of these at the time of delivery is requisite to release your order from customs on arrival.</p>


<div className="main-sub-lable">What is your Return Policy?</div>
<p>We want every customer to be pleased with their order. We don't accept returns; we ensure that the attire you receive is in perfect condition and fits you well. All custom-made orders are non-returnable.</p>


<div className="main-sub-lable">Exchange in India:</div>
<p>Step 1: To return a piece, the client must have to inform us at <Link to="mailto:support@sparkleluxury.in">support@sparkleluxury.in</Link>. You can contact us on Whatsapp: <Link to="https://api.whatsapp.com/send?phone=+918347578972&text=Hello">+91 8347578972</Link> within 14 days of delivery of the shipment.</p>
<p>Step 2: Please wait until your email has been confirmed. We will manage to collect the courier via our courier partner. In some unavoidable situations, if their service isn't available at your address, we will request you to self-ship the courier at our location.</p>

<div className="main-sub-lable">Exchange outside india</div>
<p>Unfortunately, we don't provide an exchange or return facility for international orders unless the acquired product is damaged or different from what had been ordered.</p>



              </div>
          </div>
      </section>

  
    </div>
  );
};

export default PagePaymentPolicy;