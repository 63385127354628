import React from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Sparkle Luxury</title>
        <meta name="description" content="Welcome to our world of Luxury Imitation Jewellery! At Sparkle Luxury, we believe every woman deserves to feel beautiful and confident, without breaking the bank. That's why we offer a stunning range of high-quality imitation jewelry that looks and feels just like the real thing, but at a fraction of the cost." />
        <meta property="og:site_name" content="Sparkle Luxury" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Welcome to our world of Luxury Imitation Jewellery! At Sparkle Luxury, we believe every woman deserves to feel beautiful and confident, without breaking the bank. That's why we offer a stunning range of high-quality imitation jewelry that looks and feels just like the real thing, but at a fraction of the cost." />
        <meta property="og:title" content="Sparkle Luxury" />
        <meta property="og:image" itemProp="image" content="https://sparkleluxuryreact.pmcommu.in/og-img.jpg" />
        <meta property="og:image:url" content="https://sparkleluxuryreact.pmcommu.in/og-img.jpg" />
        <meta property="og:image:secure_url" content="https://sparkleluxuryreact.pmcommu.in/og-img.jpg" />
        <meta property="og:url" content={window.location.origin} />
      </Helmet>

      {/* MAIN APP */}
      <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
        <MyRouter />
      </div>
    </HelmetProvider>
  );
}

export default App;
