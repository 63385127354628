import React, { useState, FC, useEffect, useRef } from "react";
import { useAuth  } from '../../contains/AuthContext';
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from 'axios';
import API_HOST from '../../config/config';
import * as $ from 'jquery';

export interface PageResetProps {
  className?: string;
}

interface JQuery {
  $: any; // Replace with your types
}

const PageReset: FC<PageResetProps> = ({ className = "" }) => {
  const { token , email } = useParams<{ token: string, email: string }>();
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const location_from = location.state?.from?.pathname || "/";

  const [errMsg, setErrMsg] = useState<any[]>([]);
  const [successMsg, setSuccessMsg] = useState<any[]>([]);

  const [showPassword, setShowPassword] = useState(false);
  

  const formRef = useRef<HTMLFormElement>(null);
  useEffect(() => {
    if(Object.keys(successMsg).length > 0)
    {
      setTimeout(function () {
        setSuccessMsg([]);
      }, 10000);
    }
  }, [successMsg]);
  useEffect(() => {
    if (formRef.current) {
      setErrMsg([]);
      setSuccessMsg([]);
      ($ as any)(formRef.current).validate({
        rules: {
          password: {
            required: true,
            minlength: 8,
          },
          password_confirmation: {
            required: true,
            minlength: 8,
            equalTo: '#password',
          },
        },
        messages: {
          password: {
            required: 'New password is required',
            minlength: 'Minimum 8 characters required',
          },
          password_confirmation: {
            required: 'Confirm password is required',
            minlength: 'Minimum 8 characters required',
            equalTo: 'New password and confirm password must match',
          },
        },
        errorPlacement: function (error: any, element: any) {
          error.addClass('block text-right p-1 text-[14px] text-[#fa826a]');
          error.insertAfter(element);
        },
        submitHandler: function (form: HTMLFormElement) {
          // Handle form submission logic here
          setErrMsg([]);
          const formData = new FormData(form);
          try {
            axios.post(API_HOST+'/api/reset-password',formData)
            .then(response => {
              if(response.data.success === 0)
              {
                var successArray: Array<any> = [];
                successArray["success" as any] = response.data.message;
                setSuccessMsg(successArray);
                //login(response.data.data);
                // const { from } = (location.state as { from: { pathname: string } }) || { from: { pathname: '/' } };
                // navigate(from);
               // window.location.href = location_from;
              }
            })
            .catch(error => {
              var newArray: Array<any> = [];              
              if(!error?.response)
              {
                newArray["server_error" as any] = "No Server Response";
              }
              else if(error.response.data.success == 2)
              {
                if(typeof error.response.data.data == 'object' && error.response.data.data !== null && Object.keys(error.response.data.data).length > 0)
                {                  
                  Object.keys(error.response.data.data).forEach((item:any, index:any) => {
                    newArray[item] = error.response.data.data[item].toString();
                  });                  
                }
                else
                {
                  newArray['server_error' as any] = error.response.data.message;
                }
              }
              else if(error.response?.status === 409 )
              {
                newArray["server_error" as any] = "Login Failed";
              }
              else{
                newArray['server_error' as any] = "Login Failed";
              }
              setErrMsg(newArray);
            });
          } catch (error) {
            console.error('Error fetching data:', error);
          }          
        },

      });
    }
  }, []);
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Reset Password || Sparkle Luxury</title>
      </Helmet>
      <div className="container mt-16 mb-16">
        <h2 className="mt-10 mb-5 flex items-center text-3xl font-semibold text-neutral-900 dark:text-custom-golden justify-center">
          Reset Password
        </h2>
         
        {/* <p className="mb-5 max-w-md mx-auto text-center text-neutral-300 text-[16px] leading-relaxed">We get it, stuff happens. Just enter your email address below and we'll send you a link to reset your password!</p> */}
        {
            (Object.keys(errMsg).length > 0) && (
              <div role="alert">
                <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                  Error
                </div>
                <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                  {
                    Object.keys(errMsg).map((item:any) => (
                      <p>{errMsg[item]}</p>
                    ))
                  }
                </div>
              </div>
            )
          }
          {
            (Object.keys(successMsg).length > 0) && (
              <div role="alert">
                <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2">
                  Success
                </div>
                <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700">
                  {
                    Object.keys(successMsg).map((item:any,index:any) => (
                      <p key={index}>{successMsg[item]}</p>
                    ))
                  }
                </div>
              </div>
            )
          }
        <div className="max-w-md mx-auto space-y-6 ">
          <form ref={formRef} className="grid grid-cols-1 gap-3" action="#" method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                New Password
              </span>
              <Input
                id="password"
                name="password"
                type="password"
                placeholder="Enter new password" 
                className="mt-1 h-12"
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Confirm Password
              </span>
              <Input
                id="password_confirmation"
                name="password_confirmation"
                type="password"
                placeholder="Enter confirm password" 
                className="mt-1 h-12"
              />
            </label>
            <input type="hidden" name="token" value={token} />
            <input type="hidden" name="email" value={email} />
            <ButtonPrimary type="submit">Update</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="text-[14px] block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link className="transition text-primary-300 hover:text-primary-500" to="/login">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageReset;
