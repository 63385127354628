import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";


const PageReturn: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Return and Exchange Policy || Sparkle Luxury</title>
      </Helmet>

      <div className="breadcrumb-main-bx">
          <div className="lagle-container">
              <div className="breadcrumb-bx">
                  <Link className="breadcrumb-link" to="/">Home</Link>
                  <div className="breadcrumb-link breadcrumb-active">/ Return and Exchange Policy</div>
              </div>
          </div>
      </div>

      <section className="pt-16 pb-16">
          <div className="lagle-container">
              <div className="lagle-heading">Return and Exchange Policy</div>
              <div className="legal-content">
                
                 <p>We want you to love your shopping experience with us, but kindly inform us if something is wrong.</p>

                 <p>As a standard company protocol across all direct points of sales, Sparkle Luxury doesn't countersign any return or exchange policy irrespective of domestic or international order, neither on off the rack nor on made to order attires, except in the case of the below mentioned exceptional scenarios:</p>

                 <p>Any merchandise you have acquired from us is not in good condition or is damaged or defective.</p>

                 <p>If there is an irreversible technical issue with an acquired attire against the client specifications, for example - sizing issue, fabric issue, color mismatch, length issue, etc.</p>

                 <p>In all the mentioned scenarios, the client must apprise <Link to="/privacy">privacy policy</Link> you may return the merchandise unused and in the remaining condition, in its untouched packaging with actual tags, for an exchange, within 14 days from the date on which these merchandises are delivered. Once acquired, the customer care team will check it out; your credit/exchange will be processed if all conditions are matched.</p>

                 <p>However, the decision will be taken within 2-3 business days of receiving the attire, and you will aptly apprise of the same. No Return or Exchanges are allowed on discounted attires.</p>

              </div>
          </div>
      </section>

  
    </div>
  );
};

export default PageReturn;
