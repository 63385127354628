import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainNav2Logged from "./MainNav2Logged";
import CountdownTimer from "./CountdownTimer";
import axios from 'axios';
import API_HOST from 'config/config';

export interface HeaderLoggedProps {}

const HeaderLogged: FC<HeaderLoggedProps> = () => {
  const [seconds,setSeconds] = useState(0);
  const [minutes,setMinutes] = useState(0);
  const [hours,setHours] = useState(0);
  const [offerText,setOfferText] = useState('');
  const [offerLink,setOfferLink] = useState('javascript:void(0)');

  useEffect(() => {
    const fetchSettings = async () => { 
      try {
        axios.post(API_HOST+'/api/settings',{
        })
        .then(response => {
          if(response.data.success === 0)
          {
            setSeconds(response.data.data.offer_timer_sec)
            setMinutes(response.data.data.offer_timer_min)
            setHours(response.data.data.offer_timer_hour)
            setOfferText(response.data.data.offer_timer_text)
            setOfferLink(response.data.data.offer_timer_link)
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });  
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchSettings();
  }, []);
  return (
    <>

      {offerText && <div className="header-top-strip">
        <div className="hts-inner-bx">
          <Link to={offerLink}>{offerText}</Link>
          { hours && minutes && seconds && <CountdownTimer hours={hours} minutes={minutes} seconds={seconds} />
          }
        </div>
      </div>}
      <div className="nc-HeaderLogged sticky top-0 w-full z-40 ">
        <MainNav2Logged />
      </div>
    </>
  );
};

export default HeaderLogged;
