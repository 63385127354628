import React, { FC, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import NotifyAddTocart from "./NotifyAddTocart";
import AccordionInfo from "containers/ProductDetailPage/AccordionInfo";
import { Link } from "react-router-dom";
import { useCart } from '../context/CartContext';

export interface ProductQuickViewProps {
  className?: string;
  data?: {
    id?:number;
    name?: string;
    price?:number;
    discounted_price?:number;
    description?:string;
    faq?:string;
    link?:any;
    image?:string;
    images?:string[];
    isLiked?: boolean;
    favourite?:boolean;
    attribute_detail?:any[];
    product_colors?:product_color[];
    stock?:number;
  };
}
interface product_color {
  id:number;
  name:string;
  photo:number;
  product_color_id:number;
 
}

const ProductQuickView: FC<ProductQuickViewProps> = ({ className = "" , data = {} }) => {
  const { addToCart } = useCart();
  const { sizes, variants, status, allOfSizes } = PRODUCTS[0];
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];
  const images = (data?.images?.length ? data?.images : []);
  images?.shift();

  var newAccordionDetailArray: Array<any> = [];  
  if(data.description && data.description != "<p><br></p>")
  {
    newAccordionDetailArray.push({'name' : 'Description','content' : data.description});
  }
  if(data.faq && data.faq != "<p><br></p>")
  {
    newAccordionDetailArray.push({'name' : 'FAQ','content' : data.faq});
  }

  const attribute_detail = (data?.attribute_detail?.length ? [data?.attribute_detail[0]] : []);  

  const [variantActive, setVariantActive] = React.useState(0);
  const [size, setSize] = React.useState(((attribute_detail.length > 0) ? attribute_detail[0]['all_value'] : []));
  const [attributeSelected, setAttributeSelected] = React.useState(((attribute_detail.length > 0) ? ((attribute_detail[0]['all_value'].length > 0) ? attribute_detail[0]['all_value'][0] : {}) : {}));
  const product_colors = (data?.product_colors?.length ? data?.product_colors : []);
  const [colorSelected,setColorSelected ] = React.useState((product_colors.length > 0) ? product_colors[0] : []);
  const [sizeSelected, setSizeSelected] = React.useState(sizes ? sizes[0] : "");
  const [qualitySelected, setQualitySelected] = React.useState(1);
 
  useEffect(() => {
    setColorSelected(product_colors[variantActive])
  }, [variantActive]);
  // const notifyAddTocart = () => {
  //   toast.custom(
  //     (t) => (
  //       <NotifyAddTocart
  //         productImage={LIST_IMAGES_DEMO[0]}
  //         qualitySelected={qualitySelected}
  //         show={t.visible}
  //         sizeSelected={sizeSelected}
  //         variantActive={variantActive}
  //       />
  //     ),
  //     { position: "top-right", id: "nc-product-notify", duration: 3000 }
  //   );
  // };

  const renderVariants = () => {
    if (!variants || !variants.length) {
      return null;
    }

    return (
      <div>
        <label htmlFor="">
          <span className="text-sm font-medium">
            Color:
            <span className="ml-1 font-semibold">
              {variants[variantActive].name}
            </span>
          </span>
        </label>
        <div className="flex gap-2 mt-2.5">
          {variants.map((variant, index) => (
            <div
              key={index}
              onClick={() => setVariantActive(index)}
              className={`relative flex w-[44px] h-[44px] rounded-full border-2 cursor-pointer ${
                variantActive === index
                  ? "border-custom-golden dark:border-custom-golden"
                  : "border"
              }`}
            >
              <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
                <img
                  src={variant.thumbnail}
                  alt=""
                  className="absolute w-full h-full object-cover"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSizeList = () => {
    if (!allOfSizes || !sizes || !sizes.length) {
      return null;
    }
    return (
      <div>
        <div className="flex justify-between font-medium text-sm">
          <label htmlFor="">
            <span className="">
              Size:
              <span className="ml-1 font-semibold">{sizeSelected}</span>
            </span>
          </label>
          {/* <a
            target="_blank"
            rel="noopener noreferrer"
            href="##"
            className="text-primary-6000 hover:text-primary-500"
          >
            See sizing chart
          </a> */}
        </div>
        <div className="flex gap-2 mt-2.5">
          {allOfSizes.map((size, index) => {
            const isActive = size === sizeSelected;
            const sizeOutStock = !sizes.includes(size);
            return (
              <div
                key={index}
                className={`relative w-[44px] h-[44px] rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${
                  sizeOutStock
                    ? "text-opacity-20 dark:text-opacity-20 cursor-not-allowed"
                    : "cursor-pointer"
                } ${
                  isActive
                    ? "bg-custom-golden border-custom-golden text-white hover:bg-custom-golden"
                    : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
                }`}
                onClick={() => {
                  if (sizeOutStock) {
                    return;
                  }
                  setSizeSelected(size);
                }}
              >
                {size}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    if (!status) {
      return null;
    }
    const CLASSES =
      "absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300";
    if (status === "New in") {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "50% Discount") {
      return (
        <div className={CLASSES}>
          <IconDiscount className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "Sold Out") {
      return (
        <div className={CLASSES}>
          <NoSymbolIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "limited edition") {
      return (
        <div className={CLASSES}>
          <ClockIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    return null;
  };

  const renderSectionContent = () => {
    return (
      <div className="space-y-4">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-2xl font-semibold hover:text-custom-golden transition-colors">
            <Link to={data.link}>{data.name}</Link>
          </h2>

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            {/* <div className="flex text-xl font-semibold">$112.00</div> */}
            <Prices
              contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
              price={((attributeSelected?.attribute_price) ? (attributeSelected?.attribute_price) : (data.price))}
              discounted_price={data.discounted_price}
            />

            {/*<div className="h-6 border-l border-slate-300 dark:border-slate-700"></div>

            <div className="flex items-center">
              <Link
                to={data.link}
                className="flex items-center text-sm font-medium"
              >
                <StarIcon className="w-5 h-5 pb-[1px] text-custom-golden" />
                <div className="ml-1.5 flex">
                  <span>4.9</span>
                  <span className="block mx-2">·</span>
                  <span className="text-slate-600 dark:text-slate-400 underline">
                    142 reviews
                  </span>
                </div>
              </Link>
              <span className="hidden sm:block mx-2.5">·</span>
              <div className="hidden sm:flex items-center text-sm">
                <SparklesIcon className="w-3.5 h-3.5" />
                <span className="ml-1 leading-none">{status}</span>
              </div>
            </div>*/}
          </div>
        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
        {/* <div className="">{renderVariants()}</div> */}
         {/* ---------- 3 VARIANTS AND SIZE LIST ----------  {renderVariants()}*/}
         <div className="">
          {product_colors.length > 0 &&
            <div>
              <label htmlFor="">
                <span className="text-sm font-medium">
                  Color:
                  <span className="ml-1 font-semibold">
                    {product_colors[variantActive] ? product_colors[variantActive].name :''}
                  </span>
                </span>
              </label>
              <div className="flex mt-3 gap-2">
                {product_colors.map((variant:any, index:any) => (
                  <div
                    key={index}
                    onClick={() => 
                      //setColorSelected(product_colors[variantActive]?.product_color_id)
                      setVariantActive(index)
                     
                    }
                    className={`relative flex  w-[44px] h-[44px] rounded-full border-2 cursor-pointer ${
                      variantActive === index
                        ? "border-custom-golden dark:border-custom-golden"
                        : "border"
                    }`}
                  >
                    <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
                      <img
                        src={variant?.photo}
                        alt=""
                        className="absolute w-full h-full object-cover"
                      />
                    </div>
                  </div>
                ))
                }
              </div>
            </div>
          }
            
        </div>
        {/* <div className="">{renderSizeList()}</div> */}
        {/*<div className="">{renderSizeList()}</div>*/}

        {
          attribute_detail.length > 0 && (
            attribute_detail.map((item:any, index:any) => {
            return (<div key={index}>
              <div className="flex justify-between font-medium text-sm">
                <label htmlFor="">
                  <span className="capitalize">
                    {item.name} :
                    <span className="ml-1 font-semibold uppercase">{attributeSelected.value}</span>
                  </span>
                </label>                
              </div>
              <div className="grid grid-flow-col auto-cols-max gap-2 mt-2.5">
                {
                  item.all_value.map((size:any, index2:any) => {
                  const isActive = ((size.product_attribute_value_id === attributeSelected.product_attribute_value_id) && (size.product_attribute_id === attributeSelected.product_attribute_id));
                  const sizeOutStock = false;
                  return (
                    <div
                      key={index2}
                      className={`relative w-[44px] h-[44px] px-2 h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                      text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${
                        sizeOutStock
                          ? "text-opacity-20 dark:text-opacity-20 cursor-not-allowed"
                          : "cursor-pointer"
                      } ${
                        isActive
                          ? "bg-custom-golden border-custom-golden text-white hover:bg-custom-golden"
                          : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
                      }`}
                      onClick={() => {
                        if (sizeOutStock) {
                          return;
                        }
                        setAttributeSelected(size);
                      }}
                    >
                      {size.value}
                    </div>
                  );
                })}
              </div>
            </div>)
            })
          )
        }

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        {data.stock && data.stock > 0 && <div className="flex space-x-3.5">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            <NcInputNumber
              defaultValue={qualitySelected}
              onChange={setQualitySelected}
            />
          </div>
          <ButtonPrimary
            className="flex-1 flex-shrink-0"
            onClick={() => addToCart({...data,selected_attribute:attributeSelected,selected_color:colorSelected},qualitySelected)}//{notifyAddTocart}
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">Add to cart</span>
          </ButtonPrimary>
        </div>
        }

        {/*  */}
        <hr className=" border-slate-200 dark:border-slate-700"></hr>
        {/*  */}

        {/* ---------- 5 ----------  */}
        <AccordionInfo
          data={newAccordionDetailArray}
        />
      </div>
    );
  };

  return (
    <div className={`nc-ProductQuickView ${className}`}>
      {/* MAIn */}
      <div className="lg:flex">
        {/* CONTENT */}
        <div className="w-full lg:w-[50%] ">
          {/* HEADING */}
          <div className="relative">
            <div className="aspect-w-16 aspect-h-16">
              <img
                src={data.image || ''}
                className="w-full rounded-xl object-cover bg-[white]"
                alt="product detail 1"
              />
            </div>

            {/* STATUS */}
            {/*{renderStatus()}*/}
            {/* META FAVORITES */}
            <LikeButton liked={data.favourite || false} className="absolute right-3 top-3 " productId={data.id}/>
          </div>
          {/*<div className="hidden lg:grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-5 xl:mt-5">
            {images.map((item : any, index) => {
              return (
                <div key={index}>
                  <img
                    src={item}
                    className="w-full rounded-xl object-cover"
                    alt="product detail 1"
                  />
                </div>
              );
            })}
          </div>*/}
        </div>

        {/* SIDEBAR */}
        <div className="w-full lg:w-[50%] pt-6 lg:pt-0 lg:pl-7 xl:pl-8">
          {renderSectionContent()}
        </div>
      </div>
    </div>
  );
};

export default ProductQuickView;
