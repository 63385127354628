import React, { FC } from "react";

export interface PricesProps {
  className?: string;
  price?: number;
  discounted_price?:number;
  contentClass?: string;
}

const Prices: FC<PricesProps> = ({
  className = "",
  price = 33,
  discounted_price = 0,
  contentClass = "py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium",
}) => {
  return (
    <div className={`flex gap-1 items-start ${className}`}>
      <div
        className={`flex items-center border-2 border-[#22c55e] rounded-lg whitespace-nowrap ${contentClass}`}
      >
        <span className="text-green-500 !leading-none">
          INR {discounted_price > 0 ? discounted_price.toFixed(0) : price?.toFixed(0)}
          {/* INR {price} */}
        </span>
      </div>
      {discounted_price > 0 && 
      <div
        className={`flex items-center border-2 border-transparent rounded-lg whitespace-nowrap ${contentClass}`}
      >
        <del className="text-[#525252] opacity-70 !leading-none">
          INR {price?.toFixed(0)}
          {/* INR {price} */}
        </del>
      </div>
      }
    </div>
  );
};

export default Prices;
