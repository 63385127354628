import React, { FC, useEffect, useState } from 'react';
import { Link } from "react-router-dom";

// Slick SLider Files
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import axios from 'axios';
import API_HOST from '../../config/config';

// import banner1 from "../../images/banner/banner(3).webp";
// import banner2 from "../../images/banner/banner(4).webp";
// import banner3 from "../../images/banner/banner(5).webp";
// import banner4 from "../../images/banner/banner(6).webp";
// import banner5 from "../../images/banner/banner(7).webp";
// import banner6 from "../../images/banner/banner(8).webp";
// import banner7 from "../../images/banner/banner(9).webp";
// import banner8 from "../../images/banner/banner(2).webp";

interface Hero2DataType {
  id:number;
  image: string;
  heading: string;
  sub_heading: string;
  btn_text?: string;
  btn_link?: string;
}
export interface SectionHero2Props {
  className?: string;
}

const SectionBanner: React.FC = ({ 


}) => {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    const fetchData = async () => { 
      try {
        axios.post(API_HOST+'/api/get-home-data',{
          nopaginate : '1',
          status : '1',
          order : 'asc',
          type:'banners'
        })
        .then(response => {
          if(response.data.success === 0)
          {
            setBanners(response.data.data.banners);
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });  
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };


  return (
    <div className="product-slider-container">
      <Slider {...sliderSettings} className="main-slider nav-warro-white">
        {banners.map((banner:Hero2DataType,index) => {
          return (
           <Link to={banner.btn_link ? banner.btn_link : '/'}>
            <img style={{width:"100%"}} className="" src={banner.image} alt={banner.image} />
            </Link>
          );
        })}
        {/* <Link to={"/"}>
            <img className="" src={banner1} alt="" />
        </Link>
        <Link to={"/"}>
            <img className="" src={banner2} alt="" />
        </Link>
        <Link to={"/"}>
            <img className="" src={banner3} alt="" />
        </Link>
        <Link to={"/"}>
            <img className="" src={banner4} alt="" />
        </Link>
        <Link to={"/"}>
            <img className="" src={banner5} alt="" />
        </Link>
        <Link to={"/"}>
            <img className="" src={banner6} alt="" />
        </Link>
        <Link to={"/"}>
            <img className="" src={banner7} alt="" />
        </Link>
        <Link to={"/"}>
            <img className="" src={banner8} alt="" />
        </Link> */}
      </Slider>
    </div>
  );
};

export default SectionBanner;
