import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";


const PageTerms: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Terms & Conditions || Sparkle Luxury</title>
      </Helmet>

      <div className="breadcrumb-main-bx">
          <div className="lagle-container">
              <div className="breadcrumb-bx">
                  <Link className="breadcrumb-link" to="/">Home</Link>
                  <div className="breadcrumb-link breadcrumb-active">/ Terms & conditions</div>
              </div>
          </div>
      </div>

      <section className="pt-16 pb-16">
          <div className="lagle-container">
              <div className="lagle-heading">Terms & conditions</div>
              <div className="legal-content">

                  <p>Thank you for considering our Terms and Conditions at Sparkle Luxury By using our website, you agree to comply with and be bound by these terms and conditions.</p>
                
                  <div className="main-sub-lable">1. Intellectual Property Rights</div>
                  <p>All content on our website, including text, images, logos, and designs, is owned by Sparkle Luxury and is protected by intellectual property laws. You may not use our content without our prior written permission.</p>

                  <div className="main-sub-lable">2. Use of Our Website</div>
                  <p>You may use our website for personal and non-commercial purposes only. You may not use our website for any illegal or unauthorized purpose or to violate any laws.</p>

                  <div className="main-sub-lable">3. Product Descriptions</div>
                  <p>We make every effort to ensure that the product descriptions on our website are accurate and complete. However, we do not guarantee the accuracy or completeness of the product descriptions.</p>

                  <div className="main-sub-lable">4. Pricing and Payment</div>
                  <p>All prices on our website are in INR and are subject to change without notice. We accept payment by COD/ONLINE. You are responsible for all applicable taxes and duties.</p>

                  <div className="main-sub-lable">5. Shipping and Delivery</div>
                  <p>We will make every effort to ship your order as quickly as possible. However, we do not guarantee delivery dates or times. You are responsible for providing accurate shipping information.</p>

                  <div className="main-sub-lable">6. Returns and Refunds</div>
                  <p>We accept returns within 3 days of delivery for unused and undamaged products. Refunds will be issued to the original payment method.</p>

                  <div className="main-sub-lable">7. Disclaimer of Warranties</div>
                  <p>We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of respect to the website or the information, products, services, or related graphics contained on the website for any purpose.</p>

                  <div className="main-sub-lable">8. Limitation of Liability</div>
                  <p>We will not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from the use of our website or the purchase of our products.</p>
                  <p>You agree to indemnify and hold Sparkle Luxury harmless from any claims, damages, or expenses arising out of your use of our website or your violation of these terms and conditions.</p>

                  <div className="main-sub-lable">9. Governing Law and Jurisdiction</div>
                  <p>These terms and conditions shall be governed by and construed by the laws of INDIAN Govt. Any disputes arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of India.</p>

                  <p>If you have any questions or concerns about our Terms and Conditions, please contact us at <a href="tel:+918347578972 ">+91 8347578972 </a></p>

                  {/* <p>Sparkle Luxury</p>
                  <p>Email Id: <a href="mailto:support@sparkleluxury.in">support@sparkleluxury.in</a>.</p>
                  <p>Phone number: <a href="tel:+918347578972 ">+91 8347578972 </a>.</p> */}

              </div>
          </div>
      </section>

  
    </div>
  );
};

export default PageTerms;
