import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";


import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import SectionHero from "./SectionHero";
// import SectionFounder from "./SectionFounder";
// import SectionStatistic from "./SectionStatistic";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";
// import SectionPromo3 from "components/SectionPromo3";
// import rightImg from "images/hero-right1.png";

// import visionmission from "images/visionmission.png";
import Socialicon from "components/Socialicon";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Sparkle Luxury</title>
      </Helmet>

      <div className="breadcrumb-main-bx">
          <div className="lagle-container">
              <div className="breadcrumb-bx">
                  <Link className="breadcrumb-link" to="/">Home</Link>
                  <div className="breadcrumb-link breadcrumb-active">/ About</div>
              </div>
          </div>
      </div>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <section className="pt-16 pb-16 relative z-10">
          <div className="lagle-container">
              <div className="lagle-heading">About</div>
              <div className="legal-content">
                    <p>Welcome to our world of Luxury Imitation Jewellery!</p>
                    <p>At Sparkle Luxury, we believe every woman deserves to feel beautiful and confident, without breaking the bank. That's why we offer a stunning range of high-quality imitation jewelry that looks and feels just like the real thing, but at a fraction of the cost.</p>
                    <p>Our team of experienced designers and craftsmen is passionate about creating exquisite jewelry pieces that are both timeless and contemporary, using only the finest materials and techniques. From elegant necklaces and bracelets to sparkling earrings and rings, our collection offers something for every occasion and style.</p>
                    <p>But we don't just sell jewelry - we also offer exceptional customer service and a personalized shopping experience. Our knowledgeable and friendly team is always on hand to help you choose the perfect piece, and we take great pride in ensuring that every customer is satisfied with their purchase.</p>
                    <p>So why not indulge in a bit of luxury today, and discover the beauty of Sparkle Luxury imitation jewelry?</p>
              </div>

              <Socialicon className="mt-4 mb-4" />

              <Link className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium py-2 px-5 ttnc-ButtonSecondary bg-white text-slate-700 dark:bg-slate-900 dark:text-slate-300 hover:bg-gray-100 dark:hover:bg-slate-800 flex-1 border border-slate-200 dark:border-slate-700" to="/contact">Contact</Link>

          </div>
      </section>

      {/* <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        />

        <SectionFounder />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic />

        <SectionPromo3 />
      </div> */}
    </div>
  );
};

export default PageAbout;
