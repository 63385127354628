import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";


const PageCancellation: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Cancellation Policy || Sparkle Luxury</title>
      </Helmet>
      <div className="breadcrumb-main-bx">
          <div className="lagle-container">
              <div className="breadcrumb-bx">
                  <Link className="breadcrumb-link" to="/">Home</Link>
                  <div className="breadcrumb-link breadcrumb-active">/ Cancellation Policy</div>
              </div>
          </div>
      </div>
      <section className="pt-16 pb-16">
          <div className="lagle-container">
              <div className="lagle-heading">Cancellation Policy</div>
              <div className="legal-content">
                
                 <p>You have an option available of Cancelling the merchandise you purchase and claiming a refund. However, this is only permitted 24 hours from the time of purchase. In such case, a Return in the form of a 'credit note' will be instigated within 7 business days from the date of order cancellation. For any cancellation requests acquired after 24 hours from the purchase, Pharav will have no accountability in association with any assert for a Refund.</p>

              </div>
          </div>
      </section>

  
    </div>
  );
};

export default PageCancellation;

