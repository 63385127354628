import React, { FC, useState, useEffect, useRef } from "react";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import * as $ from 'jquery';
import 'jquery-validation';
import axios from 'axios';
import API_HOST from '../../config/config';
import { Helmet } from "react-helmet-async";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Socialicon from "components/Socialicon";
import { Link } from "react-router-dom";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import SectionPromo1 from "components/SectionPromo1";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "Company",
    desc: "Sparkle Luxury",
    tdlink: "javascript:void(0);",
  },
  {
    title: "EMAIL",
    desc: "support@sparkleluxury.in",
    tdlink: "mailto:support@sparkleluxury.in",
  },
  {
    title: "PHONE",
    desc: "+91 8347578972",
    tdlink: "tel:+918347578972 ",
  },
  // {
  //   title: "ADDRESS",
  //   desc: "G 38 Satyam Flats opp Balaji garden Restaurant satellite 380015",
  //   tdlink: "javascript:void(0);",
  // },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {

  const [errMsg, setErrMsg] = useState<any[]>([]);
  const [successMsg, setSuccessMsg] = useState<any[]>([]);

  const [phoneValue, setPhoneValue] = useState<any>('');
  const [phoneMsg, setPhoneMsg] = useState<any>('');
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleInputChange = (val: any) => {
    setPhoneValue(val || '');
    setIsValid((!!val && isValidPhoneNumber((val || ''))));
    if(val == '')
    {
      setPhoneMsg('Phone Number is required');
    }
    else if((!!val && isValidPhoneNumber((val || ''))) == false)
    {
      setPhoneMsg('Please enter a valid number.');
    }
    else if(val != '' && ((!!val && isValidPhoneNumber((val || ''))) == true))
    {
      setPhoneMsg('');
    }    
  };

  var phoneNumberCheckCount = 0;
  const phoneNumberCheck = () => {
    setIsValid((!!phoneValue && isValidPhoneNumber(phoneValue || '')));
    if(phoneValue == '')
    {
      setPhoneMsg('Phone Number is required');
    }
    else if((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == false)
    {
      setPhoneMsg('Please enter a valid number.');
    }
    else if(phoneValue != '' && ((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == true))
    {
      setPhoneMsg('');
    }
    if(phoneNumberCheckCount == 0)
    {
      setPhoneMsg('');
    }
    phoneNumberCheckCount = phoneNumberCheckCount+1;
    return (!!phoneValue && isValidPhoneNumber((phoneValue || '')));
  }

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (formRef.current) {  
      if(!formRef.current.checkValidity())
      {
        phoneNumberCheck();    
      }
      ($ as any)(formRef.current).validate({
        rules: {
          first_name: {
            required: true,
          },
          last_name: {
            required: true,
          },
          email: {
            required: true,
            email: true,
          },
          message: {
            required: true,
          },
        },
        messages: {
          first_name: {
            required: 'First Name is required',
          },
          last_name: {
            required: 'Last Name is required',
          },
          email: {
            required: 'Email is required',
            email: 'Invalid email address',
          },
          message: {
            required: 'Message is required',
          },
        },
        errorPlacement: function (error: any, element: any) {
          error.addClass('block text-right p-1 text-[14px] text-[#fa826a]');
          if (element.attr("name") == "phone" ) {
            // error.appendTo(($ as any)(element).parents('div').find(($ as any)('.phone_input_div')));
          }
          else
          {
            error.insertAfter(element);
          }
        },        
      });
    }
  }, []);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrMsg([]);
    setSuccessMsg([]);
    phoneNumberCheck();
    if (formRef.current) {      
      if(formRef.current.checkValidity())
      {
        if(phoneNumberCheck() == true)
        {
          setErrMsg([]);
          setSuccessMsg([]);
          const formData = new FormData(formRef.current);
          if((isValidPhoneNumber((phoneValue || ''))) == true)
          {
            const phoneNumber = parsePhoneNumber(phoneValue);
            if (phoneNumber) {
              formData.append('phone', `${phoneNumber.number}`);
              formData.append('phone_country', `${phoneNumber.country}`);
            }
          }
          try {
            axios.post(API_HOST+'/api/contact_us_mail',formData)
            .then(response => {
              if(response.data.success === 0)
              {
                if (formRef.current) {
                  setPhoneValue('');
                  formRef.current.reset();
                }
                var successArray: Array<any> = [];
                successArray["success" as any] = response.data.message;
                setSuccessMsg(successArray);
              }
            })
            .catch(error => {
              console.log("errorerror==",error);
              var newArray: Array<any> = [];
              if(!error?.response)
              {
                newArray["server_error" as any] = "No Server Response";
              }
              else if(error.response.data.success == 2)
              {
                if(typeof error.response.data.data == 'object' && error.response.data.data !== null && Object.keys(error.response.data.data).length > 0)
                {                  
                  Object.keys(error.response.data.data).forEach((item:any, index:any) => {
                    newArray[item] = error.response.data.data[item].toString();
                  });                  
                }
                else
                {
                  newArray['server_error' as any] = error.response.data.message;
                }
              }
              else if(error.response?.status === 409 )
              {
                newArray["server_error" as any] = "Contact us Failed";
              }
              else{
                newArray['server_error' as any] = "Contact us Failed";
              }
              setErrMsg(newArray);
            });
          } catch (error) {
            var newArray: Array<any> = [];
            newArray['server_error' as any] = "Contact us Failed";
            setErrMsg(newArray);
            console.error('Error fetching data:', error);
          }
        }
      }
      else
      {
        phoneNumberCheck();
      }
    }
  }

  useEffect(() => {},[errMsg]);

  useEffect(() => {
    if(Object.keys(successMsg).length > 0)
    {
      setTimeout(function () {
        setSuccessMsg([]);
      }, 10000);
    }
  }, [successMsg]);

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact || Sparkle Luxury</title>
      </Helmet>

      <div className="breadcrumb-main-bx">
          <div className="lagle-container">
              <div className="breadcrumb-bx">
                  <Link className="breadcrumb-link" to="/">Home</Link>
                  <div className="breadcrumb-link breadcrumb-active">/ Contact</div>
              </div>
          </div>
      </div>

      <div className="">
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-custom-golden dark:text-custom-golden justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto pb-20">
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
            <div className="max-w-full space-y-4">
              {info.map((item, index) => (
                <a href={item.tdlink} key={index} className="block border border-neutral-200 hover:bg-neutral-100  rounded-lg p-2 px-4 hover:dark:border-custom-golden hover:dark:bg-[#d2af6d1a]">
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-1 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </a>
              ))}

              <div>
                <Socialicon className="justify-center mt-5" />
              </div>
              {/* <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div> */}
            </div>
            <div>
              {
                (Object.keys(errMsg).length > 0) && (
                  <div role="alert">
                    <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                      Error
                    </div>
                    <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                      {
                        Object.keys(errMsg).map((item:any,index:any) => (
                          <p key={index}>{errMsg[item]}</p>
                        ))
                      }
                    </div>
                  </div>
                )
              }
              {
                (Object.keys(successMsg).length > 0) && (
                  <div role="alert">
                    <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2">
                      Success
                    </div>
                    <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700">
                      {
                        Object.keys(successMsg).map((item:any,index:any) => (
                          <p key={index}>{successMsg[item]}</p>
                        ))
                      }
                    </div>
                  </div>
                )
              }
              <form ref={formRef} onSubmit={handleFormSubmit} encType="multipart/form-data" className="grid grid-cols-1 sm:grid-cols-2 gap-6" >

                <label className="block">
                  <Label className="text-sm">First name</Label>

                  <Input
                    id="first_name"
                    name="first_name"
                    placeholder="First name"
                    type="text"
                    className="mt-1 h-12"
                  />
                </label>

                <label className="block">
                  <Label className="text-sm">Last name</Label>

                  <Input
                    id="last_name"
                    name="last_name"
                    placeholder="Last name"
                    type="text"
                    className="mt-1 h-12"
                  />
                </label>

                <label className="block">
                  <Label className="text-sm">Email address</Label>

                  <Input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter email address"
                    className="mt-1 h-12"
                  />
                </label>

                <label className="block">
                  <Label className="text-sm">Phone</Label>

                  <PhoneInput
                    inputclass={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 h-12`}
                    international={false}
                    defaultCountry="AE"
                    value={phoneValue}
                    onChange={handleInputChange}
                  />

                  {(phoneMsg) && <label id="" className={` block text-right p-1 text-[14px] text-[#fa826a]`} >{phoneMsg}</label>}
                </label>
                <label className="block sm:col-span-2">
                  <Label className="text-sm">Message</Label>

                  <Textarea id="message" name="message" className="mt-1" rows={6} />
                </label>
                <div className="sm:col-span-2 text-center">
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative my-24 lg:my-32 py-24 lg:py-32">
          <BackgroundSection />
          <SectionPromo1 />
        </div>
      </div> */}
    </div>
  );
};

export default PageContact;
