import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { NavLink } from "react-router-dom";
import Socialicon from "components/Socialicon";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  // {
  //   id: "5",
  //   title: "Getting started",
  //   menus: [
  //     { href: "#", label: "Release Notes" },
  //     { href: "#", label: "Upgrade Guide" },
  //     { href: "#", label: "Browser Support" },
  //     { href: "#", label: "Dark Mode" },
  //   ],
  // },
  {
    id: "1",
    title: "",
    menus: [
      { href: "/", label: "Home" },
      { href: "/about", label: "About" },
      { href: "/faqs", label: "FAQs" },
      { href: "/contact", label: "Contact" },
      { href: "/sitemap", label: "Sitemap" },
    ],
  },
  {
    id: "2",
    title: " ",
    menus: [
      { href: "/return", label: "Return Policy" },
      { href: "/privacy", label: "Privacy Policy" },
      { href: "/payment-policy", label: "Payment Policy" },
      { href: "/cancellation", label: "Cancellation Policy" },
      { href: "/terms", label: "Terms & Conditions" },
    ],
  },
  {
    id: "4",
    title: "Contact Info",
    menus: [
      { href: "tel:+918347578972 ", label: '+91 8347578972 ' },
      { href: "mailto:support@sparkleluxury.in", label: "support@sparkleluxury.in" },
      // { href: "javascript:void(0);", label: "G 38 Satyam Flats opp Balaji garden Restaurant satellite 380015" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title} &nbsp;
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <NavLink
                key={index}
                className={({ isActive }) => `${ isActive ? "text-custom-golden" : "text-neutral-6000 hover:text-black" }`}
                to={item.href}
                // target="_blank"
                // rel="noopener noreferrer"
              >
                {item.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative pt-20 lg:pt-28 lg:pb-0 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-3 lg:grid-cols-4 lg:gap-x-10 ">
        
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-full lg:col-span-1 text-center lg:text-left">
            <Logo className="footer-logo" />
            <div className="text-[14px] text-neutral-6000 hover:text-black">
              Welcome to our world of Luxury Imitation Jewellery!
            </div>
            <Socialicon className="justify-center lg:justify-start mt-3" />
          </div>
          <div className="hidden col-span-2 items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
          </div>
        </div>

        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <div className="container mt-8">
        <div className="footer-bottom">
          <div className="copyright">© 2024 Sparkle Luxury. All Rights Reserved.</div>
          <div className="mede-with">Made with
            <span className="icons-box-made">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
              </svg>
            </span>
            by <a href={"https://pmcommu.com/"}  rel="noreferrer" target="_blank">PM Communications</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
