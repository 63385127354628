import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ hours = 0, minutes = 0, seconds = 0 }) => {
  const [timer, setTimer] = useState({ hours, minutes, seconds });
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  const [currentSeconds,setCurrentSeconds] = useState(totalSeconds);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (totalSeconds <= 0) {
        clearInterval(intervalId);
      } else {
        
        const updatedSeconds = currentSeconds - 1;
        setCurrentSeconds(updatedSeconds);
        const updatedHours = Math.floor(updatedSeconds / 3600);
        const updatedMinutes = Math.floor((updatedSeconds % 3600) / 60);
        const updatedRemainingSeconds = updatedSeconds % 60;
       
        setTimer({
          hours: updatedHours,
          minutes: updatedMinutes,
          seconds: updatedRemainingSeconds
        });
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentSeconds]);

  return (
    <span>{`${timer.hours.toString().padStart(2, '0')}:${timer.minutes.toString().padStart(2, '0')}:${timer.seconds.toString().padStart(2, '0')}`}</span>
  );
};

export default CountdownTimer;