import Label from "components/Label/Label";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from '../../contains/axios';
import API_HOST from '../../config/config';
import { useAuth  } from '../../contains/AuthContext';
import * as $ from 'jquery';
import 'jquery-validation';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { Helmet } from "react-helmet-async";
import CommonLayout from "./CommonLayout";

interface JQuery {
  $: any; // Replace with your types
}

const hidepassSvg = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
    </svg>
  )
}

const viewpassSvg = () => {
  return(
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
  </svg>
  )
}

const AccountPass = () => {
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [errMsg, setErrMsg] = useState<any[]>([]);
  const [successMsg, setSuccessMsg] = useState<any[]>([]);

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (user) {
      if (user?.token && user?.role && user?.role=='user') {
      }
      else
      {
        navigate('/login');
      }
    }
    else
    {
      navigate('/login');
    }
  }, [user, navigate]);

  // Create an array of states, one for each password field
  const [showPasswords, setShowPasswords] = useState([false, false, false]);

  // Function to toggle the visibility of a specific password field
  const togglePasswordVisibility = (index: number) => {
    const updatedShowPasswords = [...showPasswords];
    updatedShowPasswords[index] = !updatedShowPasswords[index];
    setShowPasswords(updatedShowPasswords);
  };

  useEffect(() => {
    if (formRef.current) {
      const firstInput = formRef.current.querySelector('input');
      if (firstInput) {
        firstInput.focus();
      }
      setErrMsg([]);
      setSuccessMsg([]);
      ($ as any).validator.addMethod("phone_exp", function(this: any,value:any, element:any) {
          return this.optional(element) || /^[0-9]{10}$/.test(value);
      }, "Please enter a valid number.");
      ($ as any)(formRef.current).validate({
        rules: {
          current_password: {
            required: true,
          },
          new_password: {
            required: true,
          },
          confirm_password: {
            required: true,
            equalTo: "#new_password"
          },          
        },
        messages: {
          current_password: {
            required: 'Current Password is required',
          },
          new_password: {
            required: 'New Password is required',
          },
          confirm_password: {
            required: 'Confirm Password is required',
          },          
        },
        errorPlacement: function (error: any, element: any) {
          error.addClass('block text-right p-1 text-[14px] text-[#fa826a]');          
          error.insertAfter(element);
        },
        submitHandler: function (form: HTMLFormElement) {
          setErrMsg([]);
          setSuccessMsg([]);

          const formData = new FormData(form);
          formData.append('id',`${user?.id}`);
          
          try {
            axios.post(API_HOST+'/api/change-password',formData)
            .then(response => {
              if(response.data.success === 0)
              {
                var successArray: Array<any> = [];
                successArray["success" as any] = response.data.message;
                setSuccessMsg(successArray);
                if (formRef.current) {                  
                  formRef.current.reset();
                  const firstInput_focus = formRef.current.querySelector('input');
                  if (firstInput_focus) {
                    firstInput_focus.focus();
                  }
                }
              }
            })
            .catch(error => {
              var newArray: Array<any> = [];              
              if(!error?.response)
              {
                newArray["server_error" as any] = "No Server Response";
              }
              else if(error.response.data.success == 2)
              {
                if(typeof error.response.data.data == 'object' && error.response.data.data !== null && Object.keys(error.response.data.data).length > 0)
                {                  
                  Object.keys(error.response.data.data).forEach((item:any, index:any) => {
                    newArray[item] = error.response.data.data[item].toString();
                  });                  
                }
                else
                {
                  newArray['server_error' as any] = error.response.data.message;
                }
              }
              else if(error.response?.status === 409 )
              {
                newArray["server_error" as any] = "Password Change Failed";
              }
              else{
                newArray['server_error' as any] = "Password Change Failed";
              }
              setErrMsg(newArray);
            });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        },

      });
      
    }
  }, []);

  useEffect(() => {},[errMsg]);
  useEffect(() => {
    if(Object.keys(successMsg).length > 0)
    {
      setTimeout(function () {
        setSuccessMsg([]);
      }, 10000);
    }
  }, [successMsg]);

  return (
    <div>
      <Helmet>
        <title>Change password || Sparkle Luxury</title>
      </Helmet>

      <div className="breadcrumb-main-bx">
        <div className="lagle-container">
            <div className="breadcrumb-bx">
              <Link className="breadcrumb-link" to="/">Home</Link>
              <div className="breadcrumb-link breadcrumb-active">/ Change password</div>
            </div>
        </div>
      </div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            Update your password
          </h2>
          {
            (Object.keys(errMsg).length > 0) && (
              <div role="alert">
                <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                  Error
                </div>
                <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                  {
                    Object.keys(errMsg).map((item:any,index:any) => (
                      <p key={index}>{errMsg[item]}</p>
                    ))
                  }
                </div>
              </div>
            )
          }
          {
            (Object.keys(successMsg).length > 0) && (
              <div role="alert">
                <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2">
                  Success
                </div>
                <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700">
                  {
                    Object.keys(successMsg).map((item:any,index:any) => (
                      <p key={index}>{successMsg[item]}</p>
                    ))
                  }
                </div>
              </div>
            )
          }
          <form ref={formRef} encType="multipart/form-data" >
            <div className=" max-w-xl space-y-6">
              {[0, 1, 2].map((index) => (
                <div key={index} className="relative">
                  <Label>{index === 0 ? "Current" : index === 1 ? "New" : "Confirm"} password</Label>
                  <Input
                    id={index === 0 ? "current_password" : index === 1 ? "new_password" : "confirm_password"}
                    name={index === 0 ? "current_password" : index === 1 ? "new_password" : "confirm_password"}
                    type={showPasswords[index] ? 'text' : 'password'}
                    placeholder={`Enter ${index === 0 ? "Current" : index === 1 ? "New" : "Confirm"} password`}
                    className="mt-1.5 pr-[45px] h-12"
                  />
                  <span
                    onClick={() => togglePasswordVisibility(index)}
                    className="cursor-pointer flex justify-center align-middle w-[30px] h-[30px] absolute right-[12px] top-[39px]"
                  >
                    {showPasswords[index] ? viewpassSvg() : hidepassSvg()}
                  </span>
                </div>
              ))}
              <div className="pt-2">
                <ButtonPrimary type="submit" >Update password</ButtonPrimary>
              </div>
            </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
